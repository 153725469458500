import React from 'react';
import axios from "axios";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import ReactModal from "react-modal";

export const PlayersMoneyDialog = (props) => {
    const {onClose, isOpen, confirm} = props;
    const [data, setData] = React.useState(null);
    const isMobile = useSelector((state) => state.isMobileView);
    const {t} = useTranslation();
    const i18nPrefix = "personal.moneyInfo.";
    const tr = (key) => t(i18nPrefix + key);

    React.useEffect(() => {
        if (isOpen) {
            setData(null)
            axios.get('/api/personal/players-money').then(res => {
                setData(res.data);
                if (!res.data.hasMoney) {
                    onContinue();
                }
            });
        }
    }, [isOpen])

    function onContinue() {
        Promise.resolve().then(confirm).then(onClose)
    }

    const Content = () => {
        if (!data) return <React.Fragment/>
        return (
            <div className="form-group row modal-window" style={{minWidth: '1000px'}}>
                <div className="between">
                    {data.ok && <div>{tr("title.warning")}</div>}
                    {!data.ok && <div style={{color: "red"}}>{tr("title.error")}</div>}
                    {!isMobile && <button onClick={onClose}>
                        <span className="glyphicon glyphicon-remove"/>
                    </button>}
                </div>
                <br/>
                <div>{tr("info")}</div>
                <div>{tr("text")}</div>
                {data.inWeb && (
                    <div>
                        <br/>
                        <b><div>{tr("web")}</div></b>
                    </div>
                )}
                {data.ok && (
                    <div className="button-container">
                        <button className="btn btn-default" onClick={onClose}>{tr("cancel")}</button>
                        <button className="btn btn-primary" onClick={onContinue}>{tr("continue")}</button>
                    </div>
                )}
            </div>
        )
    }

    if (isMobile) {
        return <div>
            <MobileDualHeader firstHeader={t("personal.title")} secondHeader={tr('title.' + data.ok ? "warning" : "error")}/>
            <Content/>
        </div>;
    } else {
        return <div>
            <ReactModal isOpen={isOpen} className="Modal" overlayClassName="Overlay" onRequestClose={onClose}>
                <Content/>
            </ReactModal>
        </div>
    }
}