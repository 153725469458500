import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Input from "../../forms/Input";
import { form, resultType } from "../../validation/FormController";
import FreezeForm from "../common/FreezeForm";

export type DesktopRestrictionProps = {
    handleOpenModal: Function | null;
    f: resultType;
    maxDepositPerDay: string;
    maxDepositPerMonth: string;
    maxStepsPerMonth: string;
    maxStepsPerDay: string;
    onSubmitFreeze: Function;
    handleCloseModal: () => void;
    historyStepsOkSubmit: Function | null;
    timeoutInMinutes: string;
    showModal: boolean;
}

export function DesktopRestriction({
                                       handleOpenModal,
                                       maxDepositPerMonth,
                                       f,
                                       handleCloseModal,
                                       maxStepsPerMonth,
                                       maxDepositPerDay,
                                       historyStepsOkSubmit,
                                       maxStepsPerDay,
                                       onSubmitFreeze,
                                       timeoutInMinutes,
                                       showModal
}: DesktopRestrictionProps) {
    const { t } = useTranslation();
    const i18nPrefix = 'restrictions.';

    return (
        <div>
            <form name="restrictions" className="form-horizontal" onSubmit={(e) => handleOpenModal && handleOpenModal(e)}>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>{t('restrictions.descriptions.header')}</th>
                        <th>{t('restrictions.commonRestrictions.header')}</th>
                        <th>{t('restrictions.personalRestrictions.header')}</th>
                    </tr>
                    <tr>
                        <td scope="row">{t('restrictions.descriptions.maxDepositPerDay')}</td>
                        <td>{maxDepositPerDay} {t('restrictions.rub')}</td>
                        <td>
                            <Input f={f('maxDepositPerDay')}/>
                        </td>
                    </tr>
                    <tr>
                        <td scope="row">{t('restrictions.descriptions.maxDepositPerMonth')}</td>
                        <td>{maxDepositPerMonth} {t('restrictions.rub')}</td>
                        <td><Input f={f('maxDepositPerMonth')}/></td>
                    </tr>
                    <tr>
                        <td scope="row">{t('restrictions.descriptions.maxStepsPerDay')}</td>
                        <td>{maxStepsPerDay} {t('restrictions.rub')}</td>
                        <td>
                            <Input f={f('maxStepsPerDay')}/>
                        </td>
                    </tr>
                    <tr>
                        <td scope="row">{t('restrictions.descriptions.maxStepsPerMonth')}</td>
                        <td>{maxStepsPerMonth} {t('restrictions.rub')}</td>
                        <td>
                            <Input f={f('maxStepsPerMonth')}/>
                        </td>
                    </tr>
                    <tr>
                        <td scope="row">{t('restrictions.descriptions.timeoutInMinutes')}</td>
                        <td>{timeoutInMinutes} {t('restrictions.minutes')}</td>
                        <td>
                            <Input f={f('timeoutInMinutes')}/>
                        </td>
                    </tr>
                    </thead>
                </table>

                <button className="btn btn-primary" disabled={f.errors.length > 0}>{t('restrictions.applyBtn')}</button>
            </form>
            <FreezeForm onSubmitFreeze={onSubmitFreeze}/>

            <Modal isOpen={showModal}
                   contentLabel="Step"
                   className="Modal"
                   overlayClassName="Overlay"
                   shouldCloseOnOverlayClick={true}
            >
                <div className="form-group row" style={{ backgroundColor: "#b9fe9d" }}>
                    <div className="size-div">
                        <div>{t(i18nPrefix + 'alertPartOne')}</div>
                        <div>{t(i18nPrefix + 'alertPartTwo')}</div>
                    </div>
                    <div className="text-center size-div">{t(i18nPrefix + 'continue')}</div>
                    <div className="text-center">
                        <div>
                            <button className="btn btn-primary size-div"
                                    onClick={(e) => historyStepsOkSubmit && historyStepsOkSubmit(e)}>
                                {t('history.steps.ok')}
                            </button>
                            <button className="btn btn-primary size-div"
                                    onClick={handleCloseModal}>
                                {t('history.steps.cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}


