import React from 'react';
import { withTranslation } from "react-i18next";

import PrintButton from '../commons/comps/print-button/Print';
import CloseButton from '../commons/comps/close-button/Close';

import ArrowLeft from './arrow_left.png';
import ArrowRight from './arrow_right.png';

import './bingo.css'

import {ChessBoard} from '../commons/comps/chess/ChessBoard';
import {isChessEventType} from '../commons/chess/ChessEventTypes';

import { dateFormatter, timeFormatter } from "../commons/DateTimeUtils";

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render () {
        const {print, close, t, receiptNo} = this.props;
        return (
            <div className="header section bg">
                <PrintButton print={ print }/>
                <div>{t("quittance.bingo.title", { receiptNumber : receiptNo })}</div>
                <div className="subHeader">{t("quittance.bingo.subtitle" )}</div>
                <CloseButton close={ close }/>
            </div>
        );
    }
}

class Quittance extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render () {
        const {step, t, print, close} = this.props;
        const session = step.events[0].session;
        const {eventType, denomination}  = session.details;

        const rate = {};
        rate.credits = Math.round(step.betAmount / denomination);
        rate.currency = step.betAmount;

        const result = {};
        result.credits = Math.round(step.winAmount / denomination);
        result.currency = step.winAmount;

        if (!isChessEventType(eventType)) {
            console.log("Unknown bingo EventType " + eventType);
            return <div/>
        } else {
            return <ChessQuittance step={step} print={print} close={close} t={t} rate={rate} result={result}/>
        }
    }
}

class ChessQuittance extends React.PureComponent {
    constructor(props) {
       super(props);
    }

    render() {
        const {step, t, print, close, rate, result} = this.props;
        const session = step.events[0].session;
        const {rateDetails, bingoDetails, game} = session.details;
        const symbols = bingoDetails.result;
        const stepNo = step.id;
        const receiptNo = step.receiptNumber;
        const championship = rateDetails.championship;
        const tour = rateDetails.tour;
        return (
            <div className={"bingo-quittance-wrapper"}>
                <div>
                    <div className={['quittance', 'bingo', 'chess', game].join(' ')}>
                        <Header print={print} close={close} t={t} rateDetails={rateDetails} receiptNo={receiptNo}/>
                        <div className="top section">
                            <div className="top-chess-section" style={{padding: "0px 0px 10px 0px"}}>
                                <div className={'bold-text'}>{ t("quittance.bingo.stepNumber") + " " + stepNo }</div>
                                <div>{t("regularKvitok.event.title") + " " + t("regularKvitok.event.regular")}</div>                                            
                                <div>{ t("quittance.bingo.championship") + ": " + championship }</div>
                                <div>{ t("quittance.bingo.tour") + ": " + tour }</div>
                                <div className={'bold-text'}>{ t("quittance.bingo.eventNumber", {eventName: rateDetails.eventName, eventNumber: rateDetails.eventNumber}) }</div>
                                <div>{ t("quittance.bingo.eventTime") }</div>
                                <div>{ dateFormatter(rateDetails.eventTime, session.timezoneOffset) }</div>
                            </div>
                        </div>

                        <div className="bottom section bg">
                            <div>{ t("quittance.bingo.registrationTime") }</div>
                            <div>{ dateFormatter(rateDetails.registrationTime, session.timezoneOffset) }</div>
                            <div>{ t("quittance.bingo.cardsAmount", {cardsAmount : bingoDetails.tickets.length}) }</div>
                            <div>{ t("quittance.bingo.rate." + session.currencyShowType, rate) }</div>
                            <div>{ t("quittance.bingo.calculationTime") }</div>
                            <div>{ dateFormatter(rateDetails.calculationTime, session.timezoneOffset) }</div>
                            <div>{ t("quittance.bingo.cardsAmount", {
                                        cardsAmount : bingoDetails.tickets.filter(
                                            ticket => ticket.sectors
                                                .map(sector => sector.played ||
                                                        sector.lines
                                                            .map(line => line.played)
                                                            .reduce(((prev, cur) => prev || cur), false))
                                                .reduce((prev, cur) => prev || cur), false)
                                                .length
                                })}
                            </div>
                            {
                                result.credits
                                ? <div>{ t("quittance.bingo.result." + session.currencyShowType, result)}</div>
                                : <div>{ t("quittance.bingo.noprizes") }</div>
                            }
                        </div>
                        <div className="footer section">
                            <div className="chess-symbols">
                                <div className="symbols-label">{ t("quittance.keno.chess.symbols") }</div>
                                <div className="symbols-wrapper">
                                {
                                    symbols.map((symbol, index) => <div key={index}>{symbol}</div>)
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{backgroundColor: "#fef6ee", display: "flex",
                                    justifyContent: "space-between", flexWrap: "wrap",
                                    width: "500px", flexDirection: "column", border: "1px solid #C7894A"}}>
                    <div style={{width: "100%", textAlign: "center", paddingTop: "5px", fontWeight: "bold",
                         color: "#C7894A", fontSize: "9pt", textTransform: "uppercase"}}>{ t("quittance.keno.outcome") }</div>
                    <ChessBoardsContainer t={t} rateDetails={rateDetails} boards={rateDetails.chessInfos} symbols={symbols} tz={session.timezoneOffset}/>
                </div>

            </div>
        );
    }
}

class ChessBoardsContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            index: 0
        };
    }

    nextPage = () => {
        this.setState({index: this.state.index === 8 ? 0 : this.state.index + 1});
    };
    previousPage = () => {
        this.setState({index: this.state.index === 0 ? 8 : this.state.index - 1});
    };

    render () {
        const {boards, rateDetails, tz, t, symbols} = this.props;
        const pageSize = 8;
        return (
            <div style={{backgroundColor: "#fef6ee", display: "flex",
                    justifyContent: "space-around", flexWrap: "wrap",
                    width: "100%", flexGrow: "1"}}>
                <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap", alignSelf: "flex-start"}}>
                {
                boards
                    .slice(pageSize * this.state.index, pageSize * (this.state.index + 1))
                    .map((data, i) => {
                        return <div className="bingo chess-wrapper" key={i} style={{fontSize: "x-small"}}>
                            <div style={{paddingBottom: "5px", fontWeight: "bold", textAlign: "center", marginRight: "30px"}}>{ t("quittance.bingo.chess.board", {board: pageSize * this.state.index + i + 1}) }</div>
                            <div style={{display: "flex", flexDirection:"row"}}>
                                <ChessBoard fen={data.fen}/>
                                <div className="chess-info">
                                    <div style={{fontWeight: "bold", color: "#a6a6a6"}}>
                                        <div>{ t("quittance.bingo.chess.game", {game: data.matchId}) }</div>
                                        <div>{ t("quittance.bingo.chess.move", {move: data.moveNumber}) }</div>
                                        <div>{ t("quittance.bingo.chess.moveTime", {moveTime: timeFormatter(data.moveDate, tz)}) }</div>
                                    </div>
                                    <div className="chess-symbol">
                                        <div>{ t("quittance.bingo.chess.symbol", {
                                        symbol: symbols.slice(pageSize * this.state.index, pageSize * (this.state.index + 1))[i]
                                        })}</div>
                                        <div>{ t("quittance.bingo.chess.index", {
                                            index:  data.sum % (90 - (pageSize * this.state.index + i))
                                        })}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{paddingTop: "25px", fontWeight: "bold"}}>
                                { t("quittance.bingo.chess.sumAndGroup", {sum: data.sum, group: rateDetails.groups[i]}) }
                            </div>
                        </div>
                        })
                }
                </div>
                <div id="bingo-chess-navigation">
                    <img src={ArrowLeft} alt="" onClick={this.previousPage}/>
                    <div style={{alignSelf: "center"}}>{this.state.index + 1} {t("quittance.bingo.from")} { pageSize + 1 }</div>
                    <img src={ArrowRight} alt="" onClick={this.nextPage}/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Quittance);
