import React from 'react';
import { withTranslation } from "react-i18next";

class Circulation extends React.PureComponent {
    constructor(props) {
       super(props);
    }
    render() {
        const {kvitokData, championship, tour, t} = this.props;
        return (
            <div>
                <div>{t("regularKvitok.championship") + ": " + (championship ? championship : "")}</div>
                <div>{t("regularKvitok.tour") + ": " + (tour ? tour : "")}</div>
                <div className={'bold-text'}>
                    <span>{t("regularKvitok.circulation", { EVENT : kvitokData.EVENT, GAME_TYPE: kvitokData.GAME_TYPE })}</span>
                </div>
            </div>);
    }
}

export default withTranslation()(Circulation);
