import React from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {useProgressBar} from "../redux/loadingPage";
import {form, resultType} from "../validation/FormController";
import NameBirthDateGenderForm from "../components/NameBirthDateGenderForm";
import PassportForm from "../components/PassportForm";
import AdditionalInfoForm from "../components/AdditionalInfoForm";
import AddressForm from "../components/AddressForm";
import moment from "moment";
import {RouteComponentProps} from "react-router";
import {useTranslation} from "react-i18next";
import {Notification} from "react-notification-system";
import {useNotifications} from "../util/Notifications";

interface EditApplicationProps extends RouteComponentProps<{id: string}> {
    f: resultType;
    t: Function;
}
const i18nPrefix = "personal.";

export const EditApplication: React.FC<EditApplicationProps> = (props) => {
    const {history: {push}, location: {search}, match: {params: {id}}} = props;
    const [application, setApplication] = React.useState<any | null>(null);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {start, complete} = useProgressBar(dispatch);

    React.useEffect(() => {
        start();
        axios.get("/api/personal/get-edit-application", {params: {applicationId: id}})
            .then(res => setApplication(res.data))
            .finally(complete)
        return () => {
            setApplication(null);
        }
    }, [id])

    if (!application) {
        return <React.Fragment/>
    }
    return <Application account={application} id={id} date={search.slice(1)} push={push} t={t}/>
}

export const Application = form({
    i18nPrefix,
    initialModel: ({account}) => {
        return JSON.parse(JSON.stringify(account));
    },
    schema: (account, {t,  minAge}) => {
        const now = moment();
        const birthDate = account.birthDate
            ? moment(account.birthDate, "YYYY-MM-DD")
            : null;

        return {
            ...NameBirthDateGenderForm.schema(now, t, i18nPrefix, minAge),
            ...PassportForm.schema(now, birthDate),
            ...AdditionalInfoForm.schema,
            ...AddressForm.schema,
        };
    }
})(({f, t, id, date, push}) => {

    const {addNotification} = useNotifications();

    function handleTakeBack(e: any) {
        e.preventDefault();
        axios.post("/api/personal/take-back-application", {id, type: 'EDIT'}).then((resp) => {
            let text;
            let level: Notification['level'] = "success"
            if (resp.data) {
                text = "decline.success"
            } else {
                level = "error"
                text = "decline.error"
            }
            const prefix = "personal.statements.";
            const notification: Notification = {message: t(prefix + text), level};
            addNotification(notification);
            if (resp.data) {
                toPersonal();
            }
        })
    }

    const toPersonal = () => {
        push("/personal")
    }

    const handleDownload = () => {
        window.open(`/api/personal/download-application?applicationId=${id}`)
    };

    return (
        <form name="application" className="form-horizontal">
            <h2 className="text-center">{t(f.i18nPrefix + "title")}</h2>
            <h3 className="text-center">{t(f.i18nPrefix + "application.text")}</h3>
            <h3 className="text-center">{t(f.i18nPrefix + "application.info", {id, date})}</h3>
            <div className="row">
                <div className="col-md-6">
                    <NameBirthDateGenderForm f={f} readOnly blockedFields={[]}/>
                </div>
                <div className="col-md-6">
                    <button className="btn btn-primary btn-block" onClick={toPersonal} style={{whiteSpace: "initial"}}>
                            {t(f.i18nPrefix + "application.toPersonal")}
                    </button>
                    <button className="btn btn-primary btn-block" onClick={handleTakeBack} style={{whiteSpace: "initial"}}>
                            {t(f.i18nPrefix + "application.takeBack")}
                    </button>
                    <button className="btn btn-primary btn-block" onClick={handleDownload} style={{whiteSpace: "initial"}}>
                        {t(f.i18nPrefix + "application.download")}
                    </button>
                </div>
            </div>
            <PassportForm f={f} t={t} readOnly blockedFields={[]}/>
            <AddressForm f={f} t={t} readOnly blockedFields={[]}/>
        </form>
    )
});
