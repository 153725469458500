import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Input from "../../forms/Input";
import { form, resultType } from "../../validation/FormController";
import FreezeForm from "../common/FreezeForm";

export type MobileRestrictionProps = {
    handleOpenModal: Function | null;
    f: resultType;
    maxDepositPerDay: string;
    maxDepositPerMonth: string;
    maxStepsPerMonth: string;
    maxStepsPerDay: string;
    onSubmitFreeze: Function;
    handleCloseModal: () => void;
    historyStepsOkSubmit: Function | null;
    timeoutInMinutes: string;
    showModal: boolean;
}

export function MobileRestriction({
                                       handleOpenModal,
                                       maxDepositPerMonth,
                                       f,
                                       handleCloseModal,
                                       maxStepsPerMonth,
                                       maxDepositPerDay,
                                       historyStepsOkSubmit,
                                       maxStepsPerDay,
                                       onSubmitFreeze,
                                       timeoutInMinutes,
                                       showModal
                                   }: MobileRestrictionProps) {
    const { t } = useTranslation();
    const i18nPrefix = 'restrictions.';

    return (
        <div style={{fontSize: 12}}>
            <form name="restrictions" className="form-horizontal" onSubmit={(e) => handleOpenModal && handleOpenModal(e)} >

                <RestrictionItem f={f} value={maxDepositPerDay} headerOne={'maxDepositPerDay'} unit={'rub'}/>
                <RestrictionItem f={f} value={maxDepositPerMonth} headerOne={'maxDepositPerMonth'} unit={'rub'}/>
                <RestrictionItem f={f} value={maxStepsPerDay} headerOne={'maxStepsPerDay'} unit={'rub'}/>
                <RestrictionItem f={f} value={maxStepsPerMonth} headerOne={'maxStepsPerMonth'} unit={'rub'}/>
                <RestrictionItem f={f} value={timeoutInMinutes} headerOne={'timeoutInMinutes'} unit={'minutes'}/>

                <button className="btn btn-primary" style={{width: '100%'}} disabled={f.errors.length > 0}>{t('restrictions.applyBtn')}</button>
            </form>
            <FreezeForm onSubmitFreeze={onSubmitFreeze}/>

            <Modal isOpen={showModal}
                   contentLabel="Step"
                   className="Modal"
                   overlayClassName="Overlay"
                   shouldCloseOnOverlayClick={true}
            >
                <div className="form-group row" style={{ maxWidth: 272, backgroundColor: '#EBEBEB' }}>
                    <div className="size-div">
                        <div>{t(i18nPrefix + 'alertPartOne')}</div>
                        <div>{t(i18nPrefix + 'alertPartTwo')}</div>
                    </div>
                    <div className="text-center size-div">{t(i18nPrefix + 'continue')}</div>
                    <div className="text-center">
                        <div>
                            <button className="btn btn-primary size-div"
                                    onClick={(e) => historyStepsOkSubmit && historyStepsOkSubmit(e)}>
                                {t('history.steps.ok')}
                            </button>
                            <button className="btn btn-primary size-div"
                                    onClick={handleCloseModal}>
                                {t('history.steps.cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

function RestrictionItem({headerOne, value, f, unit} : {headerOne: string, value: string, f: resultType, unit: string}) {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                <span style={{fontWeight: 'bold'}}>{t('restrictions.descriptions.header')}</span>: {t('restrictions.descriptions.' + headerOne)}
            </div>
            <div><span style={{fontWeight: 'bold'}}>{t('restrictions.commonRestrictions.header')}</span>: {value} {t('restrictions.' + unit)}</div>
            <div>
                <div><span style={{fontWeight: 'bold'}}>{t('restrictions.personalRestrictions.header')}</span></div>
                <div>
                    <Input f={f(headerOne)}/>
                </div>
            </div>

        </div>
    )
}
