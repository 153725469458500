import React from 'react';
import {useSelector} from "react-redux";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import ReactModal from "react-modal";
import {useTranslation} from "react-i18next";
import ReactTable from "react-table";
import moment from "moment";

export const ApplicationDialog = (props) => {
    const {applications, isOpen, onRequestHide, onSelectApplication} = props;
    const isMobile = useSelector((state) => state.isMobileView);
    const {t} = useTranslation();
    const i18nPrefix = "personal.application.";
    const tr = (key) => t(i18nPrefix + key);


    const ApplicationsTable = () => {

        const makeColumns = () => {
            const i18nKeyPrefix = 'personal.application.table.headers.';
            const date_time_format = 'DD.MM.YYYY HH:mm:ss';
            return [{
                Header: t(i18nKeyPrefix + 'id'),
                accessor: 'id',
                Cell: (row) => <span>{row.value}</span>
            }, {
                Header: t(i18nKeyPrefix + 'created'),
                accessor: 'created',
                Cell: (row) => <span>{moment(row.value).format(date_time_format)}</span>
            }, {
                Header: t(i18nKeyPrefix + 'type'),
                accessor: 'type',
                Cell: (row) => <span>{t('personal.application.types.' + row.value)}</span>
            }, {
                Header: t(i18nKeyPrefix + 'place'),
                Cell: (row) => <span>{t('personal.application.table.place.lk')}</span>
            }, {
                accessor: 'id',
                Cell: (row) => {
                    return <button className="btn btn-primary btn-block" onClick={onSelectApplication(row.value, row.original.type)}>
                        {t("personal.application.table.view")}
                    </button>
                }
            }];
        };

        return (
            <div className="form-group row modal-window" style={{minWidth: '1000px'}}>
                {!isMobile && <header>
                    <button onClick={onRequestHide}>
                        <span className="glyphicon glyphicon-remove"/>
                    </button>
                    <h3>{tr('title')}</h3>
                </header>}
                <ReactTable
                    data={applications}
                    columns={makeColumns()}
                    showPagination={false}
                    minRows={1}
                />
            </div>
        )
    }

    if (isMobile) {
        return <div>
            <MobileDualHeader firstHeader={t("personal.title")} secondHeader={tr('title')}/>
            <ApplicationsTable/>
        </div>;
    } else {
        return <div>
            <ReactModal isOpen={isOpen}
                        className="Modal"
                        overlayClassName="Overlay"
                        onRequestClose={onRequestHide}>
                <ApplicationsTable/>
            </ReactModal>
        </div>
    }
}
