import React from "react";
import {useTranslation} from "react-i18next";
import './style.css'
import PrintButton from "./comps/print-button/Print";
import CloseButton from "./comps/close-button/Close";
import {ChessBoard} from "./comps/chess/ChessBoard";
import {timeFormatter} from "./DateTimeUtils";

export const QuittanceGameWrapper = ({game, children, print, receiptNumber, close, stepNo, eventName, eventNumber}) => {
    return (<div className={['chess-quittance-wrapper chessKvitokText topBottomMargin', game].join(' ')}>
        <Header print={print} receiptNumber={receiptNumber} close={close}/>
        <div className="top section">
            <StepInfo {...{stepNo, eventName, eventNumber}}/>
            {children}
        </div>
    </div>)
}

const Header = ({print, receiptNumber, close}) => {
    const {t} = useTranslation();
    return (<div className="matrixRegularKvitok-header bg">
        <PrintButton print={print}/>
        <h2>{t("quittance.base.title", {receiptNumber})}</h2>
        <h4>{t("quittance.base.subtitle")}</h4>
        <CloseButton close={close}/>
    </div>);
}

const StepInfo = ({stepNo, eventName, eventNumber}) => {
    const {t} = useTranslation();
    return (<div style={{marginBottom: "15px"}}>
        <div>{t("quittance.base.stepNumber", {stepNo})}</p>
        <div>{t("quittance.base.eventNumber", {eventName, eventNumber})}</p>
    </div>)
}

export const ChessBoardsCarousel = ({countOfPage = 1, rateDetails, timezoneOffset, game}) => {
    const {t} = useTranslation();
    const [index, setIndex] = React.useState(0);
    const [pages] = React.useState((rateDetails.chessInfos || []).length / countOfPage);
    console.log({pages})
    const handlePrev = () => setIndex(prev => prev === 0 ? pages - 1 : prev - 1);
    const handleNext = () => setIndex(prev => prev === pages - 1 ? 0 : prev + 1);
    return (
        <div className={"carousel-wrapper"}>
            <div className={['prevPage', game].join(' ')} onClick={handlePrev}/>
            <div className={['border-board-info', game].join(' ')}>
                {rateDetails.chessInfos
                    .slice(countOfPage * index, countOfPage * (index + 1))
                    .map((data, i) => (
                        <div className="chess-board-carousel-wrapper" key={i}>
                            <ChessBoard fen={data.fen}/>
                            <BoardInfo
                                i={countOfPage * index + i}
                                data={data}
                                timezoneOffset={timezoneOffset}
                                group={rateDetails.groups[countOfPage * index + i]}
                            />
                        </div>
                    ))
                }
            </div>
            <div className={['nextPage', game].join(' ')} onClick={handleNext}/>
            <div className={['carousel-pages', game].join(' ')}>
                {t("quittance.base.pagesInfo", {index: index + 1, pages})}
            </div>
        </div>
    )
}

const BoardInfo = ({data, i, timezoneOffset, group}) => {
    const {t} = useTranslation();
    return (
        <div className="chess-info text-wrapper">
            <p className="text">
                {t("quittance.tape.chess.board", {board: i + 1})}
            </p>
            <p className="text">
                {t("quittance.tape.chess.game", {game: data.matchId})}
            </p>
            <p className="text">
                {t("quittance.tape.chess.move", {move: data.moveNumber})}
            </p>
            <p className="text">
                {t("quittance.tape.chess.moveTime", {moveTime: timeFormatter(data.moveDate, timezoneOffset)})}
            </p>
            <p className="text" style={{fontWeight: 'bold'}}>
                {t("quittance.tape.chess.sumAndGroup", {sum: data.sum, group})}
            </p>
        </div>
    )
}

export const StepSize = ({credits, scores, currencyType}) => {
    return (<StepOrPrizeSize {...{credits, scores, currencyType}} name={"stepSize"}/>)
}

export const PrizeSize = ({credits, scores, currencyType}) => {
    return (<StepOrPrizeSize {...{credits, scores, currencyType}} name={"prize"}/>)
}

export const StepOrPrizeSize = ({credits, scores, currencyType, name}) => {
    const {t} = useTranslation();
    const text = `quittance.base.${name}.${currencyType}`;
    return (<span>{t(text, {credits, scores: Number(scores).toFixed(2)})}</span>)
}

export const ResultTimeTitle = () => {
    const {t} = useTranslation();
    return (<span>{t('quittance.base.stepCompleted')}</span>)
}

export const EventTimeTitle = () => {
    const {t} = useTranslation();
    return (<span>{t('quittance.base.eventTime')}</span>)
}

export const CalculationTimeTitle = () => {
    const {t} = useTranslation();
    return (<span>{t('quittance.base.calculationTime')}</span>)
}

export const StepDoneTitle = () => {
    const {t} = useTranslation();
    return (<span>{t('quittance.base.stepDone')}</span>)
}

export const Time = ({timeResult}) => {
    return (<span>{timeResult}</span>)
}

export const TextWrapper = ({children}) => {
    return (<div className={"text-wrapper"} style={{margin: "15px"}}>{children}</div>)
}

export const TextBoldWrapper = ({children}) => {
    return (<div className={"text-bold-wrapper"}>{children}</div>)
}
