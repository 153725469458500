import React from "react";
import { withTranslation } from "react-i18next";
import ChessMatrixRegularQuittance from "./regular/MatrixRegularKvitokChess";
import ChessMatrixDoubleQuittance from "./double/MatrixDoubleKvitokChess";
import {isChessEventType} from '../commons/chess/ChessEventTypes';
import {
    getCombo,
    getStepSize,
    getCurrentDoubleStepAndTotalSteps,
    getHistoryDoubleBalls,
    getCurrentDoubleBall,
    getMatrixRegularKvitokData,
    getBallsFromState,
    getExpanderSymbolFromState,
    getPrizeLines,
    isFreeSpinsPossible,
    isFreeSpinsMode,
    getTotalSteps,
    getStep,
    isEndOfSEB,
    getSelectedDoubleBall,
    getRegularCurrentWin,
    getDataForEar,
    getCurrencyShowType,
    getDenomination,
    getSEBCount,
    isMarkoPoloSebAgain,
    isSebAgainSpin
} from './Helper';

class QuittanceSelector extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    getLocaleString = (prefix, a, args) => this.props.t(prefix + "." + a, args);

    render() {
        const {step, close, print} = this.props;

        if (!isChessEventType(step.type)) {
            console.log("Unknown balls EventType " + eventType);
            return <div/>
        }

        const ptpId = step.ptpId;
        console.log("ptpId: " + ptpId);
        const state = { session: step.events[0].session };
        const denomination = getDenomination(state);
        const rate = step.betAmount;
        const prize = step.winAmount;
        const receiptNo = step.receiptNumber;
        const stepNo = step.id;
        const kvitokData = getMatrixRegularKvitokData(state, rate, prize, receiptNo, stepNo, this.getLocaleString);
        const combo = getCombo(state);
        const stepSize = getStepSize(state);
        const currencyType = getCurrencyShowType(state);
        if (ptpId === 1 || ptpId === 2) {
            const isSEBMode = isFreeSpinsMode(state);
            const curStep = getStep(state);
            const totalSteps = getTotalSteps(state);
            const freeSpinsPossible = isFreeSpinsPossible(state);
            const prizeLines = getPrizeLines(state);
            const balls = getBallsFromState(state);
            const expanderSymbol = getExpanderSymbolFromState(state);
            const sebCount = getSEBCount(state);
            const markoPoloSebAgain = isMarkoPoloSebAgain(state);
            const isSEBAgain = isSebAgainSpin(state);
            return (<ChessMatrixRegularQuittance isMatrixMode={state.isMatrixMode} expanderSymbol={expanderSymbol}
                                                 balls={balls} prizeLines={prizeLines} kvitokData={kvitokData} combo={combo} stepSize={stepSize}
                                                 animeLine={state.currentAnimeLineState} isFreeSpinsAvailable={freeSpinsPossible} currencyType={currencyType}
                                                 isSEBMode={isSEBMode} endOfSEB={isEndOfSEB(state)} game={state.session.details.game} denomination={denomination}
                                                 totalSteps={totalSteps} curStep={curStep} dataForBoard={getDataForEar(state)} close={close} print={print}
                                                 markoPoloSebAgain={markoPoloSebAgain} sebCount={sebCount} isSEBAgain={isSEBAgain} ptpId={ptpId}
            />)
        } else if (ptpId === 3) {
            const curBall = getCurrentDoubleBall(state);
            const selectedBall = getSelectedDoubleBall(state);
            const steps = getCurrentDoubleStepAndTotalSteps(state);
            const history = getHistoryDoubleBalls(state);
            return (<ChessMatrixDoubleQuittance isMatrixMode={false} curBall={curBall} selectedBall={selectedBall} close={close} print={print}
                                                curPrize={getRegularCurrentWin(state)} kvitokData={kvitokData} steps={steps} game={state.session.details.game}
                                                history={history} combo={combo} stepSize={stepSize}  dataForBoard={getDataForEar(state)} currencyType={currencyType}
                                                ptpId={ptpId}
            />);
        }
    }
}

export default withTranslation()(QuittanceSelector);