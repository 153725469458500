import React, { useState } from 'react';
import { useTranslation} from "react-i18next";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {compose} from "redux";
import { ReduxState } from "../redux/redux";
import PersonalInfo from "./PersonalInfo";
import { preloadGet} from "../util/preload";
import PreApply from "./PreApply";
import withReloading from "../Reloading";
import ChangePhoneDialog from "./ChangePhoneDialog";
import ChangeEmailDialog from "./ChangeEmailDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import {RouteComponentProps, withRouter} from "react-router";
import {useNotifications} from "../util/Notifications";
import {Notification} from "react-notification-system";
import {useProgressBar} from "../redux/loadingPage";
import {ApplicationDialog} from "./ApplicationsDialog";
import {MakeStatementDialog} from "./MakeStatementDialog";
import {EditApplicationExistDialog} from "./EditApplicationExistDialog";
import {ViewStatementDialog} from "./ViewStatementDialog";
import {PlayersMoneyDialog} from "./PlayersMoneyDialog";
import moment from "moment";

interface PersonalProps extends RouteComponentProps {
    account: any;
    blockedFields?: string[];
    doComponentReload: any;
}

const Personal: React.FC<PersonalProps> = ({account, blockedFields, doComponentReload, history: {push, goBack}}) => {

    const [phase] = useState<string>('info');
    const [showChangePhoneModal, setShowChangePhoneModal] = useState<boolean>(false);
    const [showChangeEmailModal, setShowChangeEmailModal] = useState<boolean>(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
    const [showApplicationsModal, setShowApplicationsModal] = useState<boolean>(false);
    const [showMakeStatementModal, setShowMakeStatementModal] = useState<boolean>(false);
    const [makeStatementType, setMakeStatementType] = useState<string>("");
    const [showEditApplicationExistModal, setShowEditApplicationExistModal] = useState<boolean>(false);
    const [showViewStatementApplication, setShowViewStatementApplication] = useState<boolean>(false)
    const [statementApplication, setStatementApplication] = useState<any | null>(null)
    const [applicationInfo, setApplicationInfo] = useState<any>({hasApplication: false})
    const [showPlayersMoneyModal, setShowPLayersMoneyModal] = useState(false);
    const { t, i18n } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const {addNotification} = useNotifications();
    const pushToEdit = () => push('/personal/edit');
    const goToEdit = () => {
        if (applicationInfo.edit) {
            setShowEditApplicationExistModal(true)
        } else {
            pushToEdit();
        }
    }
    const {start, complete} = useProgressBar(useDispatch());

    const handleDownload = () => {
        window.open(`/api/personal/download-application?locale=${i18n.language}&applicationId=${applicationInfo.editApplicationId}`)
    };

    React.useEffect(() => {
        loadApplicationsInfo();
    }, [])

    function loadApplicationsInfo() {
        axios.get("/api/personal/get-applications").then(resp => {
            setApplicationInfo(resp.data)
        })
    }

    const handleTakeBack = (application: any) => {
        axios.post(
            "/api/personal/take-back-application",
            application
        ).then((resp) => {
            let text;
            let level: Notification['level'] = "success"
            if (resp.data) {
                text = "decline.success"
            } else {
                level = "error"
                if (application.type === 'DELETE' || application.type === 'BLOCK' || application.type === 'UNBLOCK') {
                    text = "decline.errorDelete"
                } else {
                    text = "decline.error"
                }
            }
            const prefix = "personal.statements.";
            const notification: Notification = {message: t(prefix + text), level};
            addNotification(notification);
            loadApplicationsInfo();
            onCloseViewStateApplication();
        })
    };

    function onCloseViewStateApplication() {
        setStatementApplication(null);
        setShowViewStatementApplication(false);
        setShowApplicationsModal(false);
    }

    const handleMakeStatement = (type: string) => {
        axios.get("/api/personal/get-applications").then(resp => {
            const applicationInfo = resp.data;
            setApplicationInfo(applicationInfo)
            if (!applicationInfo.hasApplication) {
                if (type === 'DELETE') {
                    setShowPLayersMoneyModal(true);
                } else {
                    setShowMakeStatementModal(true);
                    setMakeStatementType(type);
                }
            } else {
                let text;
                if (applicationInfo.blocking) {
                    text = 'BLOCK';
                } else if (applicationInfo.unlock) {
                    text = 'UNBLOCK';
                } else if (applicationInfo.delete) {
                    text = 'DELETE';
                } else {
                    text = "any";
                }
                addNotification({level: 'error', message: t('personal.statements.exists.' + text)})
            }
        })

    }

    const handleOpenApplicationsModal = () => {
        if (applicationInfo.applications.length === 1) {
            const application = applicationInfo.applications[0];
            onSelectApplication(application.id, application.type)();
            return
        }
        setShowApplicationsModal(true);
    };

    const handleChangeEmail = () => {
        setShowChangeEmailModal(true);
    };

    const handleChangePhone = () => {
        setShowChangePhoneModal(true);
    };

    const handleChangePassword = () => {
        setShowChangePasswordModal(true);
    };

    const handleSentApplication = () => {
        doComponentReload();
    };

    const handleClosePlayersMoneyDialog = () => {
        setShowPLayersMoneyModal(false);
    }

    const handleConfirmPlayersMoneyDialog = () => {
        setMakeStatementType('DELETE');
        setShowMakeStatementModal(true);
    }

    const handleChange2FA = (enable: any) => {
        start();
        axios.post("/api/personal/change-2fa", { enable }).then(doComponentReload).finally(complete);
    }

    const onSelectApplication = (applicationId: number, type: string) => () => {
        setShowApplicationsModal(false);
        if (type === 'DELETE' || type === 'BLOCK' || type === 'UNBLOCK') {
            axios.get("/api/personal/get-state-application", {params: {applicationId, type}}).then(res => {
                setShowViewStatementApplication(true);
                setStatementApplication(res.data);
            })
        } else {
            push("/application/" + applicationInfo.editApplicationId + "?" + moment(applicationInfo.editCreated).format("DD.MM.YYYY"))
        }
    }

    const isApplicationButtonEnable = () => {
        return applicationInfo.applications && applicationInfo.applications.length > 0;
    }

    const applicationSentNotification = (data:string) => {
        addNotification({
            level: "info",
            message: t("personal.applicationSent", {
                number: data,
            }),
        });
    };

    function renderMainPersonalInfo() {
        return phase === 'info'
            ? <PersonalInfo account={account}
                            blockedFields={blockedFields}
                            editApplicationId={applicationInfo.editApplicationId}
                            stateApplicationId={applicationInfo.stateApplicationId}
                            stateCreated={applicationInfo.stateCreated ? moment(applicationInfo.stateCreated).format("DD.MM.YYYY") : undefined}
                            stateType={applicationInfo.stateType}
                            onApply={goToEdit}
                            onChangePassword={handleChangePassword}
                            onChange2FA={handleChange2FA}
                            onChangeEmail={handleChangeEmail}
                            onChangePhone={handleChangePhone}
                            onShowApplications={handleOpenApplicationsModal}
                            onShowMakeStatement={handleMakeStatement}
                            isApplicationButtonEnable={isApplicationButtonEnable}
            />
            : <PreApply onSentApplication={handleSentApplication}
            />;
    }

    if (!isMobile) {
        return <div>
            {renderMainPersonalInfo()}
            <PlayersMoneyDialog
                isOpen={showPlayersMoneyModal}
                confirm={handleConfirmPlayersMoneyDialog}
                onClose={handleClosePlayersMoneyDialog}
            />
            <ChangePhoneDialog
                addNotification={addNotification}
                isOpen={showChangePhoneModal}
                start={start}
                complete={complete}
                refresh={doComponentReload}
                onRequestHide={() => setShowChangePhoneModal(false)}/>

            <ChangeEmailDialog
                isOpen={showChangeEmailModal}
                onRequestHide={() => setShowChangeEmailModal(false)}/>

            <ChangePasswordDialog
                isOpen={showChangePasswordModal}
                onRequestHide={() => setShowChangePasswordModal(false)}/>

            <ApplicationDialog
                applications={applicationInfo.applications}
                onSelectApplication={onSelectApplication}
                isOpen={showApplicationsModal}
                onRequestHide={() => setShowApplicationsModal(false)}/>

            <MakeStatementDialog
                type={makeStatementType}
                lastName={account.lastName}
                firstName={account.firstName}
                patronymic={account.middleName}
                issueDate={account.issueDate}
                series={account.series}
                number={account.number}
                email={account.email}
                isOpen={showMakeStatementModal}
                onRequestHide={() => setShowMakeStatementModal(false)}
                loadApplicationsInfo={loadApplicationsInfo}
                applicationSentNotification={applicationSentNotification}
            />

            <EditApplicationExistDialog
                onContinue={pushToEdit}
                isOpen={showEditApplicationExistModal}
                onRequestHide={() => setShowEditApplicationExistModal(false)}/>

            {statementApplication && <ViewStatementDialog
                isOpen={showViewStatementApplication}
                onRequestHide={onCloseViewStateApplication}
                application={statementApplication}
                onTakeBack={handleTakeBack}/>}
        </div>;
    } else {
        return <div>
            {!(showChangePhoneModal
                || showChangeEmailModal
                || showChangePasswordModal
                || showApplicationsModal
                || showMakeStatementModal
                || showEditApplicationExistModal
                || showViewStatementApplication
                || showPlayersMoneyModal) && renderMainPersonalInfo()}
            {showPlayersMoneyModal && <PlayersMoneyDialog
                isOpen={showPlayersMoneyModal}
                confirm={handleConfirmPlayersMoneyDialog}
                onClose={handleClosePlayersMoneyDialog}
            />}
            {showChangePhoneModal && <ChangePhoneDialog
                addNotification={addNotification}
                isOpen={showChangePhoneModal}
                start={start}
                complete={complete}
                onRequestHide={() => setShowChangePhoneModal(false)}/>}

            {showChangeEmailModal && <ChangeEmailDialog
                isOpen={showChangeEmailModal}
                onRequestHide={() => setShowChangeEmailModal(false)}/>}

            {showChangePasswordModal && <ChangePasswordDialog
                isOpen={showChangePasswordModal}
                onRequestHide={() => setShowChangePasswordModal(false)}/>}

            {showApplicationsModal && <ApplicationDialog
                onSelectApplication={onSelectApplication}
                applications={applicationInfo.applications}
                isOpen={showApplicationsModal}
                onRequestHide={() => setShowApplicationsModal(false)}/>}

            {showMakeStatementModal && <MakeStatementDialog
                type={makeStatementType}
                lastName={account.lastName}
                firstName={account.firstName}
                patronymic={account.middleName}
                issueDate={account.issueDate}
                series={account.series}
                number={account.number}
                email={account.email}
                isOpen={showMakeStatementModal}
                onRequestHide={() => setShowMakeStatementModal(false)}
                loadApplicationsInfo={loadApplicationsInfo}
            />}

            {showEditApplicationExistModal && <EditApplicationExistDialog
                onContinue={pushToEdit}
                isOpen={showEditApplicationExistModal}
                onRequestHide={() => setShowEditApplicationExistModal(false)}/>}

            {showViewStatementApplication && statementApplication && <ViewStatementDialog
                isOpen={showViewStatementApplication}
                onRequestHide={onCloseViewStateApplication}
                application={statementApplication}
                onTakeBack={handleTakeBack}/>}
        </div>;
    }
}

type PreloadInvalidatedFieldsType = {
    blockedFields: string[];
}

type PreloadPersonalType = {
    account: any
}

type PreloadPersonalApplicationType = {
}


export default compose<React.ComponentType<PreloadPersonalApplicationType>>(
    withReloading(),
    preloadGet<PreloadInvalidatedFieldsType, string[], {}>("api/auth/invalidated-fields", "blockedFields"),
    preloadGet<PreloadPersonalType, any, PreloadInvalidatedFieldsType>("/api/personal", "account")
)(withRouter(Personal));
