import React, { useEffect } from "react";
import {compose} from "redux";
import {preloadGet} from "../../util/preload";
import {connect, useSelector} from "react-redux";
import {ReduxState} from "../../redux/redux";
import {ApplicationType, hasEditStatement, hasStatement, isBlockedByStatement, UserInfo} from "../../redux/auth";
import {confirm} from "../../util/ConfirmDialog";
import {withRouter} from "react-router";
import {
    BlockedPreloadProps,
    CategoriesPreloadProps, Category,
    CategoryGameSelectorProps,
    StateProps
} from "./interface";
import CreateSessionButton from "../CreateSessionButton";
import {useTranslation} from "react-i18next";
import {useNotifications} from "../../util/Notifications";
import {up} from "../../util/urlPrefix";
import {queryAndUpdateAuth} from "../../Auth";

function balanceIsZero(user: UserInfo) {
    return user.balance === 0 && user.sessionType === "NONE";
}

/**
 * Страница появилась в результате невнятных убеждений ОА
 * Проблема данной страницы в том, что по кнопке "Спорт" должна открываться новая страница без сессии,
 * а по кнопке "Шахматы" должна сессия должна создаваться.
 * Из-за этого кнопки пришлось делать по-разному
 */
const GameCategorySelector: React.FunctionComponent<CategoryGameSelectorProps>
    = ({ user, categories, confirm, blockedFields, history, match }) => {

    const { t, i18n } = useTranslation();
    const { addNotification } = useNotifications();
    const lang = i18n.language;
    const readonly = hasStatement(user) || balanceIsZero(user) || (blockedFields && blockedFields.length > 0) || isBlockedByStatement(user);
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const mobileDirPrefix = isMobile ? 'mobile/sports/' : '';
    let sportImage = "SPORT_" + lang.slice(0, 2);
    const urlPrefix = up(match);

    useEffect(() => {
        if (blockedFields && blockedFields.length > 0) {
            addNotification({
                message: t("payment.userConditionallyBlocked")
            });
        }
        if (balanceIsZero(user)) {
            confirm.show({
                body: t("game.notEnoughMoney"),
                ok: t("game.ok"),
                linkText: t("game.depositAction"),
                linkUrl: `${urlPrefix}/payment/deposit`
            });
        }
        if (hasEditStatement(user)) {
            addNotification({
                message: t('game.editApplicationExist')
            });
        }
        if (isBlockedByStatement(user)) {
            addNotification({
                message: t('personal.blockedByStatement')
            });
        }
    }, []);

    const redirectToSport = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        queryAndUpdateAuth().then(() => {
            if (user && user.applicationExist) {
                addNotification({
                    message: t('personal.applicationExist')
                });
                return;
            }
            history.push(`${urlPrefix}/play/sport`);
        })
    };

    return <React.Fragment>
        <h2 className="text-center">{t("play.title")}</h2>
        <div className="row col-xs-12">
            {
                !isMobile && categories.includes(Category.BASIC) && <div className="col-md-offset-2 col-md-4 text-center">
                    <CreateSessionButton game={"GAMES"} disabled={readonly}/>
                </div>
            }
            {
                categories.includes(Category.SPORT) && <div className="col-md-4 text-center">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-default" onClick={redirectToSport} disabled={readonly}>
                            <img src={require(`../logos/${mobileDirPrefix + sportImage}_TRANSPARENT.png`)} alt={t("gameSelector.games.SPORT.alt")}/>
                        </button>
                    </div>
                </div>
            }
        </div>
    </React.Fragment>
}


export default compose(
    preloadGet<CategoriesPreloadProps, Array<Category>, any>("/api/play/categories", "categories"),
    preloadGet<BlockedPreloadProps, Array<string>, any>("/api/auth/invalidated-fields", "blockedFields"),
    connect<StateProps, {}, {}, ReduxState>(state => ({ user: state.auth.user as UserInfo })),
    confirm()
)(withRouter(GameCategorySelector));