import axios from "axios";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from 'react';
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactModal from 'react-modal';
import Modal from 'react-modal';
import { useSelector } from "react-redux";
import FilterableTable from "../components/FilterableTable";
import ReportButton from "../components/ReportButton";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import { ReduxState } from "../redux/redux";
import "../restrictions/common/style.css";
import {EventList} from "./Event";
import Minus from "./Minus";
import { ColumnCellType, OperationsStateType } from "./Operations";
import PickerInput from "./PickerInput";
import Quittance from './Quittance';
import "./Quittance.css";
import {QuittanceAdditionalInfo} from './QuittanceAdditionalInfo';
import SelectionList from "./SelectionList";
import './modal.css'
import {Reports} from "./Reports";
import {ChessLiveGames} from "./chessLive/ChessLiveQuttance";

export interface StepsStateType extends OperationsStateType {
    shownRow: null;
}

export function Steps() {

    // TODO ?!?
    // UNSAFE_componentWillMount() {
    //     ReactModal.setAppElement('body');
    // }

    const { t } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const [state, setState] = useState<StepsStateType>({
        search: '',
        filterParams: {
            from: moment().startOf('day'),
            to: moment().endOf('day'),
            type: null,
            types: []
        },
        shownRow: null,
        showModal: false,
        modal: false,
        disableReportButton: true,
        email: ''
    });

    const [hasNotCompletedReport, setHasNotCompletedReport] = React.useState(false);
    const [storagePeriod, setStoragePeriod] = React.useState(90);
    const [showData, setShowData] = React.useState(true);
    const _date_time_format = 'DD.MM.YYYY HH:mm:ss';

    useEffect(() => {
        axios.post("api/history/email").then(res => setState(prev => ({...prev, email: res.data })));
        axios.get("api/history/storage-period").then(res => setStoragePeriod(res.data.value))
        return () => console.log('component Steps did unmount');
    }, []);

    const handleDisableReportButton = () => {
        setState(prev => ({...prev, disableReportButton: false}));
    };

    const handleEnableReportButton = () => {
        setState(prev => ({...prev, disableReportButton: true}));
    };

    const callbackReport = (v: boolean) => setHasNotCompletedReport(v);

    const showAlert = () => {
        //TODO complete showAlert func
        // notificationSystem.addNotification({
        //     title: t("history.steps.alert"),
        // });
        console.log('TODO notificationSystem.addNotification');
    };

    const handleCloseModal = () => {
        setState(prev => ({...prev, showModal: false}));
    };

    const handleClick = () => {
        setState(prev => ({...prev, modal: true}));
    };

    const handleCloseConfirmationWindow = () => {
        setState(prev => ({...prev, modal: false}));
        showAlert();
    };

    const handleCloseWindow = () => {
        setState(prev => ({...prev, modal: false}));
    };

    const renderReceiptNo = (row: any) => {

        return <div className="text-right">
            {
                row.original.descriminator === 'JACKBET'
                && <span className="pa-icon pa-icon-jackbet"/>
            }
            {row.value}
        </div>
    };
    const renderId = (row: any) => {
        const search = state.search;

        let value = row.value;

        if (isResultJackBetLoss(row)) {
            return null;
        }
        if (search && search.length) {
            return <span dangerouslySetInnerHTML={{__html: value.replace(search, `<span style="color:#ab1919">${search}</span>`)}}/>
        }

        return <span>{value}</span>
    };
    const renderType = (row: any) => {
        return <React.Fragment>
            {
                isResultJackBetLoss(row)
                    ? ""
                    : <span>{t('history.steps.table.type.' + row.value)}</span>
            }
        </React.Fragment>
    };
    const isResultJackBetLoss = (row: any) => {
        const {jbWin, descriminator} = row.original;
        return !jbWin && descriminator === 'JACKBET';
    };
    const renderDateTime = (row: any) => <span>{moment(row.original.date).format(_date_time_format)}</span>;
    const renderEvents = (row: any) => {
        return <EventList events={row.value}/>;
    };
    const renderSelection = (row: any) => {
        const {descriminator, ptpId, priId} = row.original;
        const isRegular = descriminator === 'BALLS' && ptpId === 3;
        const isKeno = descriminator === 'KENO' && ptpId === 7;
        const isSebIgrosoft = descriminator === 'BALLS' && ptpId === 10;
        const isChessLive = descriminator === 'CHESS_LIVE';
        if (isRegular || isKeno) {
            return <ul>{row.value.map((e: any) => <li key={e.id}>{t(e.selection)}</li>)}</ul>;
        }
        if (isSebIgrosoft) {
            const values = row.value.map((e: any) => {
                const details = e.session.details.isoftDetails;
                return details ? details.choiceCombination + 1 : '-'
            });
            return <ul>{values.map((value: any, i: number) => <li key={i}>{value}</li>)}</ul>
        }
        if (isChessLive) {
            const suffix = (ChessLiveGames.get(priId) || '').toLowerCase() + '.';
            const prefix = ptpId === 3
                ? "quittance.chesslive.selection.ebSteps."
                : ("quittance.chesslive.selection." + suffix);
            return <ul>{row.value.map((e: any) => <li key={e.id}>
                    {t("quittance.chesslive.selection.prefix") + t(prefix + e.selection)}.
                    {e.eventName}
                </li>
            )}</ul>;
        }
        return <SelectionList events={row.value}/>;
    };
    const renderBetAmount = (row: any) => <span>{numeral(row.original.betAmount).format('0.00')}</span>;
    const renderWinAmount = (row: any) => {
        if (!row.original.completed) {
            return <Minus/>;
        }
        return <span>{numeral(row.original.winAmount).format('0.00')}</span>
    };

    const makeColumns = (): Array<ColumnCellType> => {
        const i18nKeyPrefix = 'history.steps.table.headers.';

        return [{
            Header: t(i18nKeyPrefix + 'receiptNo'),
            accessor: 'receiptNumber',
            Cell: renderReceiptNo,
            width: 150,
        }, {
            Header: t(i18nKeyPrefix + 'id'),
            accessor: 'id',
            Cell: renderId
        }, {
            Header: t(i18nKeyPrefix + 'dateTime'),
            accessor: 'date',
            Cell: renderDateTime
        }, {
            Header: t(i18nKeyPrefix + 'type'),
            accessor: 'type',
            Cell: renderType,
            sortable: false
        }, {
            Header: t(i18nKeyPrefix + 'event'),
            accessor: 'events',
            Cell: renderEvents,
            sortable: false
        }, {
            Header: t(i18nKeyPrefix + 'selection'),
            accessor: 'events',
            Cell: renderSelection,
            sortable: false,
            style: { 'whiteSpace': 'unset' }
        }, {
            Header: t(i18nKeyPrefix + 'betAmount'),
            accessor: 'bet',
            Cell: renderBetAmount,
        }, {
            Header: t(i18nKeyPrefix + 'winAmount'),
            accessor: 'win',
            Cell: renderWinAmount,
        }]
    };

    const columns = makeColumns();



    const onOperationTypeChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const type = event.currentTarget.value;
        setState((oldState) => ({
            ...oldState,
            filterParams: {
                ...oldState.filterParams,
                type: type === 'ALL' ? null : type
            }
        }));
    };

    const onSearchChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setState((oldState) => ({
            ...oldState,
            search: value.length <= 16 ? value : oldState.search,
            filterParams: {...oldState.filterParams, search: value}
        }));
    };

    const checkDiffDates = (from: moment.Moment, to: moment.Moment) => {
        const days = moment.duration(to.diff(from)).asDays();
        setShowData(days <= storagePeriod);
    }

    const onFromDateChange = (time: moment.Moment) => {
        const from = time.startOf('day');
        checkDiffDates(from, state.filterParams.to)
        setState((oldState) => ({
            ...oldState,
            filterParams: {
                ...oldState.filterParams,
                from
            }
        }));
    };

    const onToDateChange = (time: moment.Moment) => {
        const to = time.endOf('day');
        checkDiffDates(state.filterParams.from, to)
        setState((oldState) => ({
            ...oldState,
            filterParams: {
                ...oldState.filterParams,
                to
            }
        }));
    };

    const getMaxDate = () => {
        const to = state.filterParams.to;
        const now = moment();

        return moment.max(now, to);
    };


    const renderDateTimePickers = (innerKey: string, i18nFilter: string) => <ReactDatePicker
        showYearDropdown
        showMonthDropdown
        fixedHeight
        maxDate={getMaxDate()}
        selected={innerKey === 'to' ? state.filterParams.to : state.filterParams.from}
        onChange={innerKey === 'to' ? onToDateChange : onFromDateChange}
        id={'operations-' + innerKey + '-date-picker'}
        customInput={<PickerInput label={t(i18nFilter + innerKey + '.label')}/>}

    />;

    const renderOptions = (i18nType: string) => {
        return (
            <>
                <label>{t(i18nType + 'label')}</label>
                <select value={state.filterParams.type || 'ALL'}
                        onChange={onOperationTypeChange}
                        className="form-control">
                    <option value="ALL">{t(i18nType + 'ALL')}</option>
                    <option value="CHESS_PIECES">{t(i18nType + 'CHESS_PIECES')}</option>
                    <option value="CHESS_PLUS">{t(i18nType + 'CHESS_PLUS')}</option>
                    <option value="CHESS_LIVE">{t(i18nType + 'CHESS_LIVE')}</option>
                    <option value="MB">{t(i18nType + 'MB')}</option>
                    <option value="SB">{t(i18nType + 'SB')}</option>
                </select>
            </>
        )
    };

    const renderForm = (i18nType: string, i18nFilter: string, i18nSearch: string) => {

        if (isMobile) {
            return <>
                <MobileDualHeader firstHeader={t('history.title')} secondHeader={t('history.steps.title')}/>
                <Reports type={"STEPS"} callback={callbackReport} days={storagePeriod}/>
                <form>
                    <div className="form-group">
                        <div className="col">
                            {renderOptions(i18nType)}
                        </div>
                    </div>

                    <label>{t(i18nFilter + 'period.label')}</label>
                    <div className="form-group">
                        <div className="col">
                            {renderDateTimePickers('from', i18nFilter)}
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="col">
                            {renderDateTimePickers('to', i18nFilter)}
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="col">
                            <label htmlFor="operations-search"
                                   style={{visibility: 'hidden'}}>
                                {t(i18nSearch + 'label')}
                            </label>
                            <input type="text"
                                   placeholder={t(i18nSearch + 'placeholder')}
                                   value={state.search}
                                   onChange={onSearchChange}
                                   className="form-control"
                                   id="operations-search"/>
                        </div>
                    </div>

                </form>

                <div>
                    <button className="btn btn-primary btn-block btn-sm"
                            style={{width: isMobile ? '100%' : 150}}
                            onClick={handleClick}
                            disabled={(state.disableReportButton && showData) || hasNotCompletedReport}>
                        {t('components.reportButton')}
                    </button>
                </div>
                {hasNotCompletedReport && <h5><i>{t("history.steps.hasNotCompletedReport")}</i></h5>}
                {!showData && !hasNotCompletedReport && <h5><i>{t("history.gtStoragePeriod")}</i></h5>}
            </>;
        } else {
            return  <>
                <h2 className="text-center">{t('history.steps.title')}</h2>
                <Reports type={"STEPS"} callback={callbackReport} days={storagePeriod}/>
                <form>
                    <div className="row">
                        <div className="form-group col-xs-3">
                            {renderOptions(i18nType)}
                        </div>
                        <div className="form-group col-xs-7">
                            <label>{t(i18nFilter + 'period.label')}</label>
                            <div>
                                <div className="col-xs-6">
                                    {renderDateTimePickers('from', i18nFilter)}
                                </div>
                                <div className="col-xs-6">
                                    {renderDateTimePickers('to', i18nFilter)}
                                </div>
                            </div>

                        </div>

                        <div className="form-group col-xs-2">
                            <label htmlFor="operations-search"
                                   style={{visibility: 'hidden'}}>
                                {t(i18nSearch + 'label')}
                            </label>
                            <input type="text"
                                   placeholder={t(i18nSearch + 'placeholder')}
                                   value={state.search}
                                   onChange={onSearchChange}
                                   className="form-control"
                                   id="operations-search"/>
                        </div>
                    </div>
                </form>

                <div>
                    <button className="btn btn-primary btn-block btn-sm"
                            style={{width: isMobile ? '100%' : 150}}
                            onClick={handleClick}
                            disabled={(state.disableReportButton && showData) || hasNotCompletedReport}>
                        {t('components.reportButton')}
                    </button>
                </div>
                {hasNotCompletedReport && <h5><i>{t("history.steps.hasNotCompletedReport")}</i></h5>}
                {!showData && !hasNotCompletedReport && <h5><i>{t("history.gtStoragePeriod")}</i></h5>}
            </>;
        }
    };

    const i18nFilter = 'history.steps.filter.';
    const i18nType = 'history.steps.filter.type.';
    const i18nSearch = 'history.steps.search.';
    const i18nPrefix = 'history.steps.';
    return (
        <div>
            {renderForm(i18nType, i18nFilter, i18nSearch)}

            <Modal isOpen={state.modal}
                   contentLabel="Step"
                   className="Modal"
                   overlayClassName="Overlay"
                   onRequestClose={handleCloseModal}
                   shouldCloseOnOverlayClick={true}
            >
                <div className="form-group row modal-window">
                    <div className="size-div">
                        {t(i18nPrefix + 'report')}
                        {state.filterParams.from.format('YYYY-MM-DD')}
                        {t(i18nPrefix + 'to')}
                        {state.filterParams.to.format('YYYY-MM-DD')}
                        <div>{t(i18nPrefix + 'send')} {state.email.toString()}</div>
                    </div>
                    <div className="text-center size-div">{t(i18nPrefix + 'continue')}</div>
                    <div className="text-center">
                        <ReportButton
                            filterParams={state.filterParams}
                            dataUrl="/api/history/steps/generateReport"
                            close={handleCloseConfirmationWindow}
                            closeModal={handleCloseWindow}
                            onOk={() => setHasNotCompletedReport(true)}
                        />
                    </div>
                </div>
            </Modal>

            <ReactModal isOpen={state.showModal}
                        contentLabel="Step"
                        className="Modal"
                        overlayClassName="Overlay"
                        onRequestClose={handleCloseModal}
                        shouldCloseOnOverlayClick={true}
            >
                <div className="quittance-wrapper">
                    <div style={{ padding: 12 }}>
                        <Quittance step={state.shownRow} close={handleCloseModal} print={window.print}/>
                        <QuittanceAdditionalInfo step={state.shownRow}/>
                    </div>
                </div>
            </ReactModal>

            <FilterableTable
                dataUrl="/api/history/steps"
                columns={columns}
                disableReport={handleDisableReportButton}
                enableReport={handleEnableReportButton}
                filterParams={state.filterParams}
                needUpdate={showData}
                defaultSort={[
                    { // the sorting model for the table
                        id: 'date',
                        desc: true
                    }
                ]}
                handleShowDialog={(contentItem) => {
                    setState(prev => ({
                        ...prev,
                        shownRow: contentItem,
                        showModal: true
                    }));
                }}
                getTrProps={(state: StepsStateType, rowInfo: any) => {
                    return {
                        onDoubleClick: () => setState(prev =>({
                            ...prev,
                            shownRow: rowInfo.original,
                            showModal: rowInfo.original.descriminator !== "SPORT" && rowInfo.original.descriminator !== "JACKBET"
                        }))
                    };
                }}
            />
        </div>
    );
}
