import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error, errorInfo});
    }

    render() {
        const {children, t} = this.props;
        const {error, errorInfo} = this.state;
        if (error) {
            return <>
                <h1>{t("somethingWentWrong")} :(</h1>
            </>;

        }
        return children;
    }
}

export default ErrorBoundary;