import React from "react";

import {useTranslation} from "react-i18next";
import LabelInput from "../../forms/LabelInput";

import axios from "axios";
import NameBirthDateGenderForm from "../../components/NameBirthDateGenderForm";
import AddressForm from "../../components/AddressForm";
import PassportForm from "../../components/PassportForm";
import AdditionalInfoForm from "../../components/AdditionalInfoForm";
import IdentificationForm from "../../components/IdentificationForm";
import {useDispatch} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {useHistory} from "react-router";
import {up} from "../../util/urlPrefix";
import {useNotifications} from "../../util/Notifications";
import {RegisterRulesForm} from "../common/RegisterRulesForm";
import {setUnmountDate, useProgressBar} from "../../redux/loadingPage";

export type DesktopRegisterForm = {
    f: any;
    mainUrl: string;
}

export const DesktopRegisterForm = ({f, mainUrl}: DesktopRegisterForm) => {

    const history = useHistory();
    const match = useRouteMatch();
    const {t} = useTranslation();
    const {addNotification} = useNotifications();
    const dispatch = useDispatch();
    const {start, complete} = useProgressBar(dispatch);
    const [submitted, setSubmitted] = React.useState<boolean>(false);

    return <form name="register" className="form-horizontal"
                 onSubmit={f.submit((account: any) => {
                         start();
                         setSubmitted(true);
                         axios.post("/api/register", account)
                             .then(resp => {
                                 if (!resp.data.fms) {
                                     addNotification({message: t("register.fms")});
                                 }
                                 history.push(`${up(match)}/register/success?email=${account.email}`);
                             })
                             .finally(() => {
                                 complete();
                             })
                     }
                 )}>

        <div className="row">
            <div className="col-md-12">
                <h2 className="text-center">{t("register.title")}</h2>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
                <NameBirthDateGenderForm f={f}/>
                <LabelInput f={f("email")} skipErrors={[]}/>
                <LabelInput f={f("phone")} placeholder="+7 (___)___-__-__"/>
                <LabelInput f={f("password")} type="password"/>
                <LabelInput f={f("passwordRepeat")} type="password"/>
            </div>
            <div className="col-md-6">
                <div className="info-box">
                    <RegisterRulesForm mainUrl={mainUrl} f={f}/>
                </div>
                <IdentificationForm f={f}/>
            </div>
        </div>
        <PassportForm f={f} t={t}/>
        <AdditionalInfoForm f={f} t={t}/>
        <AddressForm f={f} t={t}/>
        <div className="row">
            <div className="col-md-4 col-md-offset-4" >
                <button className="btn btn-primary form-control" disabled={submitted} type="submit">
                    {t("register.submit")}
                </button>
            </div>
        </div>
    </form>
};
