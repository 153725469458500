import React, {useState} from "react";
import {Wizard} from "../components/Wizard";
import {withRouter} from "react-router";
import ApplicationForm from "../personal/ApplicationForm";
import PropTypes from "prop-types";
import axios from "axios";
import {preloadGet} from "../util/preload";
import {IdentificationChoicePersonal} from "../components/IdentificationChoice";
import {up} from "../util/urlPrefix";
import {useProgressBar} from "../redux/loadingPage";
import {useDispatch} from "react-redux";
import {compose} from "redux";
import {queryAndUpdateAuth} from "../Auth";

const IdentificationStep = ({w}) => <IdentificationChoicePersonal
    i18nPrefix="personal.identificationSelection."
    onChoice={identificationType => w({identificationType})}/>;

IdentificationStep.propTypes = {
    w: PropTypes.func.isRequired
};

const ApplicationStep = ({ account, identificationType, onSentApplication }) => {
    const {start, complete} = useProgressBar(useDispatch());
    const onSubmit = account => {
        start();
        axios.post("/api/personal/application", account).then(onSentApplication).finally(complete);
    }
    return <ApplicationForm account={account} blockedFields={[]} onSubmit={onSubmit} identificationType={identificationType}/>;
};

export const AppStepRoute = compose(
    preloadGet("/api/personal", "account"),
    preloadGet("/api/auth/invalidated-fields", "blockedFields")
)(withRouter(({match, history, account, blockedFields}) => {
    const urlPrefix = up(match);
    const {start, complete} = useProgressBar(useDispatch());
    const onSubmit = account => {
        start();
        axios.post("/api/personal/application", account)
            .then(queryAndUpdateAuth)
            .then(() => history.push(`${urlPrefix}/personal`))
            .finally(complete);
    }
    return <ApplicationForm account={account} blockedFields={blockedFields} onSubmit={onSubmit} identificationType={match.params.type}/>;
}));

const AppStep = preloadGet("/api/personal", "account")(ApplicationStep);

const PreApply = ({onSentApplication}) => <Wizard commonProps={{onSentApplication}} steps={[
    IdentificationStep,
    AppStep
]}/>;

PreApply.propTypes = {
    onSentApplication: PropTypes.func.isRequired,
};

export default PreApply;