import React from 'react';
import { withTranslation } from "react-i18next";

import PrintButton from '../commons/comps/print-button/Print';
import CloseButton from '../commons/comps/close-button/Close';
import {isChessEventType} from '../commons/chess/ChessEventTypes';

import './hat.css'

import ChessMatrixHatQuittance from "./MatrixHatKvitokChess";
import { getCombo,
    getStepSize,
    getMatrixRegularKvitokData,
    getBallsFromState,
    isFreeSpinsPossible,
    isFreeSpinsMode,
    getTotalSteps,
    getStep,
    isEndOfSEB,
    getDataForEar,
    getCurrencyShowType,
    getDenomination,
    getSEBCount,
    isMarkoPoloSebAgain } from './Helper';

import {Combinations} from "./Combinations";
import {isSebAgainSpin} from "../balls/Helper";

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render () {
        const {print, close, t, receiptNo} = this.props;
        return (
            <div className="header section bg">
                <PrintButton print={ print }/>
                <div>{t("quittance.hat.title", { receiptNumber : receiptNo })}</div>
                <div>{t("quittance.hat.subtitle" )}</div>
                <CloseButton close={ close }/>
            </div>
        );
    }
}

class Middle extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render () {
        const {t, hatDetails} = this.props;
        const ballCount = Combinations.get(hatDetails.combination)[0].split(",").length;
        return (
            <div className="middle section">
                <div>{ t("quittance.hat.outcome") }</div>
                <div className="row">
                    {
                        hatDetails.result.map((ball, index) => {
                            return <span key={index} className={ballCount >= (index + 1) ? "normal-ball" : "blackout-ball"}>
                                    { ball }
                            </span>
                        })
                    }
                </div>
            </div>
        );
    }
}

class Quittance extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render () {
        const {step, t, print, close} = this.props;
        const session = step.events[0].session;
        const {eventType, denomination}  = session.details;
        if (!isChessEventType(step.type)) {
            console.log("Unknown HAT EventType " + eventType);
            return <span/>
        }
        const rate = {};
        rate.credits = Math.round(step.betAmount / denomination);
        rate.currency = step.betAmount;

        const result = {};
        result.credits = Math.round(step.winAmount / denomination);
        result.currency = step.winAmount;

        return <ChessQuittance step={step} print={print} close={close} t={t} rate={rate} result={result}/>
    }
}

class ChessQuittance extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {step, print, close } = this.props;
        const state = { session: step.events[0].session };
        const denomination = getDenomination(state);
        const rate = step.betAmount;
        const prize = step.winAmount;
        const receiptNo = step.receiptNumber;
        const stepNo = step.id;
        const kvitokData = getMatrixRegularKvitokData(state, rate, prize, receiptNo, stepNo, this.getLocaleString);
        const combo = getCombo(state);
        const stepSize = getStepSize(state);
        const currencyType = getCurrencyShowType(state);
        const isSEBMode = isFreeSpinsMode(state);
        const curStep = getStep(state);
        const totalSteps = getTotalSteps(state);
        const freeSpinsPossible = isFreeSpinsPossible(state);
        const balls = getBallsFromState(state);
        const sebCount = getSEBCount(state);
        const markoPoloSebAgain = isMarkoPoloSebAgain(state);
        const isSEBAgain = isSebAgainSpin(state);
        return (<ChessMatrixHatQuittance isMatrixMode={state.isMatrixMode}
                                                     balls={balls} kvitokData={kvitokData} combo={combo} stepSize={stepSize}
                                                     animeLine={state.currentAnimeLineState} isFreeSpinsAvailable={freeSpinsPossible} currencyType={currencyType}
                                                     isSEBMode={isSEBMode} endOfSEB={isEndOfSEB(state)} game={state.session.details.game} denomination={denomination}
                                                     totalSteps={totalSteps} curStep={curStep} dataForBoard={getDataForEar(state)} close={close} print={print}
                                                     markoPoloSebAgain={markoPoloSebAgain} sebCount={sebCount} isSEBAgain={isSEBAgain} ptpId={step.ptpId}/>);
    }
}

export default withTranslation()(Quittance);
