import moment from "moment";
import React from 'react';
import {useSelector} from "react-redux";
import {ReduxState} from "../../redux/redux";
import {form, resultType} from "../../validation/FormController";
import {date, maxDate, minDate, pattern, required, values} from "../../validation/functionalPlugins";
import {DesktopFreezeForm} from "../desktop/DesktopFreezeForm";
import {MobileFreezeForm} from "../mobile/MobileFreezeForm";

export type FreezeFormPropTypes = {
    f: resultType;
    onSubmitFreeze: (freeze: any) => void;
}

const FreezeForm = ({f, onSubmitFreeze}: FreezeFormPropTypes) => {
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    if (isMobile) {
        return <MobileFreezeForm f={f} onSubmitFreeze={onSubmitFreeze}/>;
    } else {
        return <DesktopFreezeForm f={f} onSubmitFreeze={onSubmitFreeze}/>
    }
};

export default form({
    i18nPrefix: "restrictions.freeze.",
    initialModel: () => {
        return {
            type: undefined,
            date: undefined,
            time: undefined
        }
    },
    schema: (model: any) => ({
        type: [
            values("FOREVER", "UP_TO")
        ],
        date: [
            required(model.type === 'UP_TO'),
            date(),
            minDate(moment().add(1, 'days').startOf('day')),
            maxDate(moment().add(100, 'years').startOf('day')),
        ],
        time: [
            required(model.type === 'UP_TO'),
            date({modelDateFormat: 'HH:mm', dateFormat: 'HH:mm'}),
            pattern(/^\d{2}:\d{2}$/),
        ]
    })
})(FreezeForm);
