export function validationClass(f, extraValid) {
    return f.submittedOrDirty
        && (extraValid === false ? "has-error" : f.valid === true ? "has-success" : f.valid === false ? "has-error" : false);
}

export const fieldsMap = {
    "lastName": "surname",
    "firstName": "name",
    "middleName": "patronymic",
    "birthDate": "birthDate",

    "foreignCitizen.migrationRegistration.type": "foreignCitizen.migrationRegistration.type",
    "foreignCitizen.migrationRegistration.serial": "foreignCitizen.migrationRegistration.series",
    "foreignCitizen.migrationRegistration.number": "foreignCitizen.migrationRegistration.number",
    "foreignCitizen.migrationRegistration.issueDate": "foreignCitizen.migrationRegistration.issueDate",
    "foreignCitizen.migrationRegistration.endDate": "foreignCitizen.migrationRegistration.endDate",

    "foreignCitizen.visa.serial": "foreignCitizen.visa.serial",
    "foreignCitizen.visa.number": "foreignCitizen.visa.number",
    "foreignCitizen.visa.issueDate": "foreignCitizen.visa.issueDate",
    "foreignCitizen.visa.endDate": "foreignCitizen.visa.endDate",

    "address.region": "region",
    "address.district": "district",
    "address.city": "city",
    "address.locality": "locality",
    "address.street": "street",
    "address.house": "house",
    "address.flat": "apartment",

    "citizenship": "citizenship",
    "foreignCitizen.foreignRegistration.address": "foreignCitizen.foreignRegistration.address",

    "series": "series.and.number",
    "number": "series.and.number",
    "issueDate": "issuedDate",
    "birthPlace": "birthPlace",
    "subdivisionCode": "subdivisionCode",
    "issuer": "issuedBy",

    "innManually": "innManually",

    "email": "email",
    "phone": "mobilephone",
}

export function blockedConditionally(input, fields) {
    const map = fieldsMap;
    if (!fields) {
        return false;
    }
    return !!map[input] && fields.indexOf(map[input]) > -1;
}