import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import {hasEditStatement, hasStatement, isBlockedByStatement, UserInfo} from "../../redux/auth";
import { ReduxState } from "../../redux/redux";
import { confirm } from "../../util/ConfirmDialog";
import { useNotifications } from "../../util/Notifications";
import { preloadGet } from "../../util/preload";
import { DesktopGames } from "../desktop/DesktopGames";
import { MobileSports } from "../mobile/MobileSports";
import {up} from "../../util/urlPrefix";
import {withRouter} from "react-router";
import {BlockedPreloadProps, Category, GameSelectorProps, GamesPreloadProps, OwnProps, StateProps} from "./interface";

const GameSelector: React.FunctionComponent<GameSelectorProps> = ({ category, user, games, blockedFields, confirm, match }) => {

    const { t } = useTranslation();
    const { addNotification } = useNotifications();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const urlPrefix = up(match);

    function balanceIsZero(user: UserInfo) {
        return user.balance === 0 && user.sessionType === "NONE";
    }

    useEffect(() => {
        if (blockedFields && blockedFields.length > 0) {
            addNotification({
                message: t("payment.userConditionallyBlocked")
            });
        }
        if (balanceIsZero(user)) {
            confirm.show({
                body: t("game.notEnoughMoney"),
                ok: t("game.ok"),
                linkText: t("game.depositAction"),
                linkUrl: `${urlPrefix}/payment/deposit`
            });
        }
        if (hasEditStatement(user)) {
            addNotification({
                message: t('game.editApplicationExist')
            });
        }
        if (isBlockedByStatement(user)) {
            addNotification({
                message: t('personal.blockedByStatement')
            });
        }
    }, []);

    const readonly = hasStatement(user) || balanceIsZero(user) || (blockedFields && blockedFields.length > 0) || isBlockedByStatement(user);
    const titleKey = category === Category.BASIC ? "gameSelector.title" : "sportSelector.title";
    if (isMobile) {
        return <MobileSports games={games} titleKey={titleKey} readonly={readonly}/>
    } else {
        return <DesktopGames games={games} titleKey={titleKey} readonly={readonly}/>;
    }
};

export default compose(
    preloadGet<GamesPreloadProps, Array<string>, OwnProps>(({ category }) => "/api/play/games?category=" + category, "games", ["category"]),
    preloadGet<BlockedPreloadProps, Array<string>, OwnProps>("/api/auth/invalidated-fields", "blockedFields"),
    connect<StateProps, {}, OwnProps, ReduxState>(state => ({ user: state.auth.user as UserInfo })),
    confirm()
)(withRouter(GameSelector));
