import axios from "axios";
import React from "react";

import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import AddressForm from "../../components/AddressForm";
import IdentificationForm from "../../components/IdentificationForm";
import NameBirthDateGenderForm from "../../components/NameBirthDateGenderForm";
import PassportForm from "../../components/PassportForm";
import LabelInput from "../../forms/LabelInput";
import {MobileDualHeader} from "../../mobile-components/MobileDualHeader";
import {useNotifications} from "../../util/Notifications";
import {up} from "../../util/urlPrefix";

import {form, resultType} from "../../validation/FormController";
import {RegisterRulesForm} from "../common/RegisterRulesForm";
import {setUnmountDate, useProgressBar} from "../../redux/loadingPage";
import AdditionalInfoForm from "../../components/AdditionalInfoForm";
import {CaptchaWidget} from "../../captcha/Captcha";

type MobileRegisterFormProps = {
    f: resultType;
    mainUrl: string;
}

export const MobileRegisterForm = (props: MobileRegisterFormProps) => {

    const history = useHistory();
    const match = useRouteMatch();
    const {f, mainUrl} = props;
    const dispatch = useDispatch();
    const {start, complete} = useProgressBar(dispatch);
    const {t} = useTranslation();
    const {addNotification} = useNotifications();

    const [captchaResolved, setCaptchaResolved] = React.useState<boolean>(false);

    const verified = (ok: boolean) => setCaptchaResolved(ok);

    if (f.submit) {
        return <form name="register" className="form-horizontal" onSubmit={f.submit((account: any) => {
            start();
            axios.post("/api/register", account).then(resp => {
                if (!resp.data.fms) {
                    addNotification({message: t("register.fms")});
                }
                history.push(`${up(match)}/register/success?email=${account.email}`);
            }).finally(() => {
                complete()
            })
        })}>
            <MobileDualHeader firstHeader={t('register.title')}/>

            <div className="row">
                <div className="col-md-6">
                    <div>
                        <RegisterRulesForm mainUrl={mainUrl} f={f}/>
                    </div>
                    <IdentificationForm f={f}/>
                </div>

                <div className="col-md-6">
                    <NameBirthDateGenderForm f={f}/>
                    <LabelInput f={f("email")} colMd = {7}/>
                    <LabelInput f={f("phone")} placeholder="+7 (___)___-__-__"/>
                    <LabelInput f={f("password")} type="password"/>
                    <LabelInput f={f("passwordRepeat")} type="password"/>
                </div>

            </div>
            <PassportForm f={f} t={t}/>
            <AdditionalInfoForm f={f} t={t}/>
            <AddressForm f={f} t={t}/>
            <CaptchaWidget form="register" verified={ok => verified(ok)}/>
            <div className="row">
                <div className="col-md-4 col-md-offset-4">
                    <button style={{marginTop: "20px"}} className="btn btn-primary form-control" type="submit" disabled={!captchaResolved}>
                        {t("register.submit")}
                    </button>
                </div>
            </div>
        </form>;
    } else {
        return null;
    }

};
