import { dateFormatter } from "../commons/DateTimeUtils";

export const getSixteenGames = () => {
    return ['GAME24', 'GAME78', 'GAME130', 'GAME131', 'GAME132', 'GAME178'];
}

export const isSixteenBallsGame = (game) => {
    return getSixteenGames().includes(game);
}

export const getCorrectPrizeLines = (prizeLines) => {
    const result = [];
    const rowCount = 3;
    const columnsCount = 5;
    
    for (const lineEntity of prizeLines) {
        const coordinates = lineEntity.coordinates;
        for (let column = 0; column < coordinates.length; column++) {
            const coord = coordinates[column]
            if (coord !== -1) {
                //превращаем число в индекс в пределах [0, 15]
                const winIndex = (rowCount - (coord + 1)) * columnsCount + column;
                result.push({ index: winIndex, line: lineEntity.line });
            }
        }
    }
    return result;
};

export const getCombo = (state) => {
    if (state && state.session && state.session.details && state.session.details.drumDetails)
        return state.session.details.drumDetails.lineCount;
    else
        return 0;
};

export const getStepSize = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
        return state.session.details.drumDetails.lineRate;
    else
        return 0;
};

export const getCurrentDoubleStepAndTotalSteps = (state) => {
    var result = { step: null, totalSteps: null};
     if(state && state.session && state.session.details && state.session.details.drumDetails) {
         result.step = state.session.details.drumDetails.step;
         result.totalSteps = state.session.details.drumDetails.totalSteps;
         return result;
     } else
        return result;
};

export const getHistoryDoubleBalls = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
       return state.session.details.drumDetails.expressBetHistory || [];
    else
       return [];
};

export const getCurrentDoubleBall = (state) => {
    if (state && state.session && state.session.details && state.session.details.drumDetails) {
        return state.session.details.drumDetails.expressBetHistory[0];
    }
    return null;
};

export const getMatrixRegularKvitokData = (state, rate, prize, receiptNo, stepNo, getLocaleString) => {

    var result = getTopKvitokData(state, receiptNo, stepNo, getLocaleString);
    let denomination = getDenomination(state);
    addFromDetails(state, 'drumDetails', rate, denomination, prize, result);
    addFromDetails(state, 'isoftDetails', rate, denomination, prize, result);
    return result;
};

const addFromDetails = (state, name, rate, denomination, prize, result) => {
    if (state && state.session && state.session.details && state.session.details[name]) {
        const currentPrize = state.session.details[name].currentPrize;
        result.COMBO = state.session.details[name].lineCount;
        result.CREDITS = Math.round(rate / denomination);
        result.BALLS = rate;
        result.CREDITS_RESULT = Math.round(prize / denomination);
        result.BALLS_RESULT = prize;
        result.eb = {
            rate: rate
        };
        result.CREDITS_CURRENT_PRIZE = currentPrize;
        result.BALL_CURRENT_PRIZE = currentPrize * denomination;
    }
}

export const getTopKvitokData = (state, receiptNo, stepNo, getLocaleString) => {

    var result = { KVITNUMBER: "/", STEPNUMBER: "", GAME_TYPE: "", STEPTIME: "", EVENT: "", TIMERESULT: "", RESULTTIME: "" };

    if(state && state.session && state.session.details && state.session.details.rateDetails) {
          result.KVITNUMBER = receiptNo;
          result.STEPNUMBER = stepNo;
          result.GAME_TYPE = state.session.details.rateDetails.eventName;
          result.EVENT = state.session.details.rateDetails.eventNumber ? state.session.details.rateDetails.eventNumber : "";
          result.STEPTIME = dateFormatter(state.session.details.rateDetails.registrationTime, state.session.timezoneOffset, getLocaleString); //шаг выполнен
          result.TIMERESULT = dateFormatter(state.session.details.rateDetails.eventTime, state.session.timezoneOffset, getLocaleString); //время расчета результата
          result.RESULTTIME = dateFormatter(state.session.details.rateDetails.calculationTime, state.session.timezoneOffset, getLocaleString); //время наступления события
          result.eventType = state.session.details.eventType;
    }
    return result;
};

export const getBallsFromState = (state) => {

    if (checkSymbolsInDetailsByName(state, 'drumDetails')) {
        return getBallsFromStateByName(state, 'drumDetails');
    } else if (checkSymbolsInDetailsByName(state, 'isoftDetails')) {
        return getBallsFromStateByName(state, 'isoftDetails');
    } else {
        return [];
    }
};

const checkSymbolsInDetailsByName = (state, name) => {
    return state && state.session && state.session.details && state.session.details[name] && state.session.details[name].symbols
}

const getBallsFromStateByName = (state, name) => {
    return state.session.details[name].symbols;
}

export const getExpanderSymbolFromState = (state) => {

    if(state && state.session && state.session.details && state.session.details.drumDetails && state.session.details.drumDetails.expanderSymbol)
        return state.session.details.drumDetails.expanderSymbol;
    else
        return '';
};

export const getPrizeLines = (state) => {
    let prizeLines = [];
    if (state && state.session && state.session.details) {
        if (state.session.details.drumDetails && state.session.details.drumDetails.prizeLines) {
            prizeLines = getCorrectPrizeLines(state.session.details.drumDetails.prizeLines);
        } else if(state.session.details.isoftDetails && state.session.details.isoftDetails.prizeLines){
            prizeLines = getCorrectPrizeLines(state.session.details.isoftDetails.prizeLines);
        }
    }
    return prizeLines;
};

export const isFreeSpinsPossible = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
       return state.session.details.drumDetails.state === "INITIAL_SUPER_EXPRESS_BET";
   else
       return false;
};

export const isFreeSpinsMode = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
       return state.session.details.drumDetails.state === "SUPER_EXPRESS_BET";
   else
       return false;
};

export const getTotalSteps = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
       return state.session.details.drumDetails.totalSteps;
    else
       return 0;
};

export const getStep = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
       return state.session.details.drumDetails.step;
    else
       return 0;
};

export const isEndOfSEB = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
       return state.session.details.drumDetails.lastState === "SUPER_EXPRESS_BET"
           && state.session.details.drumDetails.state === "TAKE_OR_WIN";
    else
       return false;
};

export const getSelectedDoubleBall = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
       return state.session.details.drumDetails.expressBet;
    else
       return null;
};

export const getRegularCurrentWin = (state) => {
    if(state && state.session && state.session.details && state.session.details.drumDetails)
        return state.session.details.drumDetails.currentPrize;
    else
        return 0;
};

export const getPrizeFromLines = (lines) => {
    return lines
        .map(line => line.prize)
        .reduce((prev, cur) => prev + cur, 0);
};

export const getDenomination = (state) => {
    if(state && state.session && state.session.details && state.session.details.denomination)
        return state.session.details.denomination;
    else
        return 1;
};

/*eslint-disable*/
export const getDataForEar = (state)  => {
  var datum;
  var coefficient = 0;
  var currentCoeffCategory = 0;
  var currentGroup = 0;
  var totalSum = 0;
  var totalGroup = 0;
  var sum = 0;
  var datums = [];
  var chessInfoDatums = [];
  var symbolTriads = [];
  var drumState = '';
  var timezone = 0;
  var lines = [];
  var lineCount = 0;
  var lineRate = 0;
  var currentPrize = 0;
  let totalRegGroup = 0;

  if(state)
    var session = state.session;
  if(session) {
    var details = session.details;
    timezone = session.timezoneOffset;
  }

  if(details.drumDetails) {
      var drumDetails = details.drumDetails;
      if(drumDetails.symbols)
          var symbols = drumDetails.symbols;
      if(drumDetails.state)
          drumState = drumDetails.state;
      if(drumDetails.currentPrize)
          currentPrize = parseInt(drumDetails.currentPrize);
      if(drumDetails.lineCount)
          lineCount = parseInt( drumDetails.lineCount);
      if(drumDetails.lineRate)
          lineRate =  parseInt(drumDetails.lineRate);

      if(state && state.session && state.session.details && state.session.details.drumDetails && state.session.details.drumDetails.prizeLines)
          lines = getCorrectPrizeLines(state.session.details.drumDetails.prizeLines);

      if (session.details.drumDetails.state === "EXPRESS_BET")
          coefficient = session.details.drumDetails.currentPrize ? "2": "0";

      var result = 0;
      for(var i = 0; i < lines.length; i++) {
          result += lines[i].prize;
      }
      currentPrize = result;
  }

  if(symbols && (symbols.length === 15))
  {
    var triad =[];
    for (var i=0; i< 5 ; i++)
    {
      triad =[];
      triad.push(symbols[i]);
      triad.push(symbols[i+5]);
      triad.push(symbols[i+10]);
      symbolTriads.push(triad);
    }
  } else if (symbols && (symbols.length === 9)) {
      var triad3 =[];
      for (var k = 0; k < 3 ; k++)
      {
          triad3 =[];
          triad3.push(symbols[k]);
          triad3.push(symbols[k+3]);
          triad3.push(symbols[k+6]);
          symbolTriads.push(triad3);
      }
  } else {
    symbolTriads = [[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1],[-1,-1,-1,-1,-1]];
  }

  if (details.drumDetails) {
      if (currentPrize % (lineCount*lineRate) == 0)
          coefficient = currentPrize/(lineCount*lineRate);
      else
          coefficient = currentPrize.toString()+ "/" +(lineCount*lineRate).toString();
  }

  if(details.rateDetails){
    var groups = details.rateDetails.groups;
    if(details.rateDetails.chessInfos && details.rateDetails.chessInfos.length > 0){
      var ci;
      for (var j=0; j<details.rateDetails.chessInfos.length; j++)
      {
        ci = details.rateDetails.chessInfos[j];
        var chessInfoDatum ={};
        chessInfoDatum.fen = ci.fen;
        chessInfoDatum.matchId = ci.matchId;
        chessInfoDatum.moveNumber =  ci.moveNumber;
        chessInfoDatum.moveInfo = ci.moveInfo;
        chessInfoDatum.timeOfMove =  ci.moveDate;
        chessInfoDatum.sum = ci.sum;
        chessInfoDatum.currentRound = j+1;
        chessInfoDatums.push(chessInfoDatum);
        totalSum += ci.sum;
      }

      if( groups!= null){
        var count = 0;
        for (var i in groups) {
          count++;
          datum = { roundNumber: 0, group: 0, coeffCategory: 0};
          datum.roundNumber = i+1;
          datum.group = groups[i];
          // datum.coeffCategory = coeffCategories[i];
          datums.push(datum);
          currentGroup= groups[i];
          if (count === 6) {
              totalRegGroup = groups[i];
          }

        }
      }
    }
    totalGroup = (totalSum%2 == 0)? 2: 1;
  }
  return {
    coefficient: coefficient,
    currentCoeffCategory:currentCoeffCategory,
    currentGroup:currentGroup,
    totalSum: totalSum,
    totalGroup: totalGroup,
    datums:datums,
    symbolTriads:symbolTriads,
    sum: sum,
    chessInfoDatums: chessInfoDatums,
    drumState: drumState,
    timezone: timezone,
    result: result,
    totalRegGroup: totalRegGroup,
    championship: details.rateDetails.championship,
    tour: details.rateDetails.tour
  }
};

export const getCurrencyShowType = (state) => {
    //return "CURRENCY_CREDITS";
    //return "CURRENCY";
    //return "CREDITS";

    if(state && state.session && state.session.details){
        return state.session.details.currencyShowType;
    } else
        return "CREDITS";
};

export const getSEBCount = (state) => {
    if (state && state.session && state.session.details && state.session.details.drumDetails) {
        return state.session.details.drumDetails.sebCount;
    } else
        return 0;
};

//проверка повторного выпадения СЕБа в марко поло играх
export const isMarkoPoloSebAgain = (state) => {
    if (state && state.session && state.session.details && state.session.details.drumDetails && state.session.details.drumDetails.symbols) {
        if(state.session.details.drumDetails.symbols.length > 0) {
            let result = 0;
            for(var i = 0; i < state.session.details.drumDetails.symbols.length; i++) {
                if(state.session.details.drumDetails.symbols[i] === 10) { //marko polo SEB symbol
                    result++;
                }
            }
            return result > 2;
        }
    }
    return false;
};

export const isSebAgainSpin = (state) => {
    if (state && state.session && state.session.details && state.session.details.drumDetails && state.session.details.drumDetails.sebStepsAdd) {
        return state.session.details.drumDetails.sebStepsAdd > 0;
    }
    return false;
}