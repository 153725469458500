import React from 'react';
import PropTypes from 'prop-types';
import LabelInput from "../forms/LabelInput";
import {blockedConditionally} from "../forms/utils";
import classNames from "classnames";

import "./ContactInformationForm.less"

import {email, ruPhone} from "../validation/functionalPlugins"
import {useSelector} from "react-redux";

const ContactInformationForm = ({f, t, onChangeEmail, onChangePhone, blockedFields, disabled}) => {
    const p = f.i18nPrefix;
    const isMobile = useSelector((state) => state.isMobileView);

    const ChangeButton = ({text, ...props}) => {
        return (
            <div className="row">
                <div className="col-md-offset-4 col-md-8">
                    <button className="btn btn-primary btn-block" {...props}>
                        {text}
                    </button>
                </div>
            </div>
        )
    };

    ChangeButton.propTypes = {
        disabled: PropTypes.bool,
        text: PropTypes.string.isRequired,
    };

    const Status = ({text}) => {
        return (
            <div className="row">
                <div className={
                    classNames(
                        "col-md-offset-4",
                        "col-md-8",
                        "text-center",
                        "verified-status",
                    )
                }>
                    {text}
                </div>
            </div>
        )
    };

    Status.propTypes = {
        text: PropTypes.string.isRequired,
        verified: PropTypes.bool.isRequired,
    };

    function handleChangeEmail(e) {
        e.preventDefault();
        onChangeEmail();
    }
    function handleChangePhone(e) {
        e.preventDefault();
        onChangePhone();
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    {isMobile ? <span>{t(p + "contactTitle")}</span> : <h4 className="text-center">{t(p + "contactTitle")}</h4>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <LabelInput f={f("email")} readOnly blockedConditionally={blockedConditionally('email', blockedFields)}/>
                    <ChangeButton text={t(p + "email.changeButton")} onClick={handleChangeEmail}/>
                </div>
                <div className="col-md-6">
                    <LabelInput f={f("phone")} readOnly blockedConditionally={blockedConditionally('phone', blockedFields)}/>
                    <ChangeButton text={t(p + "phone.changeButton")} onClick={handleChangePhone} disabled={disabled}/>
                </div>
            </div>
        </div>
    );
};

ContactInformationForm.propTypes = {
    f: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onChangeEmail: PropTypes.func.isRequired,
    onChangePhone: PropTypes.func.isRequired,
    blockedFields: PropTypes.array,
};

ContactInformationForm.schema = {
    "email": email(),
    "phone": ruPhone(),
};

export default ContactInformationForm;
