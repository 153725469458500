import {resultType} from "../validation/FormController";
import {useSelector} from "react-redux";
import {ReduxState} from "../redux/redux";
import React from "react";
import LabelInput from "../forms/LabelInput";
import {inn, required} from "../validation/functionalPlugins";
import classnames from "classnames";
import axios from "axios";
import {blockedConditionally} from "../forms/utils";

type AdditionalInfoFormProps = {
    f: resultType,
    t: Function,
    blockedFields?: any[],
    readOnly?: boolean
}

const AdditionalInfoForm = (props: AdditionalInfoFormProps) => {
    const {t, f, readOnly, blockedFields} = props;
    const [guLink, setGuLink] = React.useState<string>("");
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);

    React.useEffect(() => {
        axios.get("/api/settings/gosuslugi-link").then(response => setGuLink(response.data.value))
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    {isMobile ? <span>{t(f.i18nPrefix + "additionalInfoTitle")}</span> : <h4 className="text-center">{t(f.i18nPrefix + "additionalInfoTitle")}</h4>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <LabelInput f={f("innManually")} readOnly={readOnly} blockedConditionally={blockedConditionally('innManually', blockedFields)}/>
                </div>
                {
                    !readOnly && <div className="col-md-6">
                        <a className={ classnames('btn', 'btn-primary') } type="button" href={guLink} target="_blank">
                            { t('register.innManually.findButton') }
                        </a>
                    </div>
                }
            </div>
        </div>
    );
};

AdditionalInfoForm.schema = {
    "innManually": [
        required(),
        inn()
    ]
}

export default AdditionalInfoForm;