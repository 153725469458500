import React from 'react';
import PropTypes from 'prop-types';
import { compose } from "redux";
import { useTranslation, withTranslation } from "react-i18next";
import { MobileDualHeader } from "../mobile-components/MobileDualHeader";
import { ReduxState } from "../redux/redux";
import { form, resultType } from "../validation/FormController";
import NameBirthDateGenderForm from "../components/NameBirthDateGenderForm";
import PassportForm from "../components/PassportForm";
import AddressForm from "../components/AddressForm";
import moment from "moment";
import ContactInformationForm from "../components/ContactInformationForm";
import MigrationRegistrationForm from "../components/foreigner/MigrationRegistrationForm";
import VisaForm from "../components/foreigner/VisaForm";
import AddressInRFForm from "../components/foreigner/AddressInRFForm";
import AddressInOwnCountryForm from "../components/foreigner/AddressInOwnCountryForm";
import { connect, useSelector } from "react-redux";
import AdditionalInfoForm from "../components/AdditionalInfoForm";
import {hasGameSession, hasStateApplication, isBlockedByStatement} from "../redux/auth";

type PersonalInfoProps = {
    account: any,
    blockedFields?: any[],
    f: any,
    editApplicationId?: number,
    stateApplicationId?: number,
    stateCreated?: string,
    stateType?: string,
    onApply: Function,
    onChangePassword: Function,
    onChange2FA: Function,
    onChangeEmail: any,
    onChangePhone: any,
    onShowApplications: Function,
    onShowMakeStatement: Function,
    isApplicationButtonEnable: Function
}

const i18nPrefix = "personal.";

function PersonalInfo(props: PersonalInfoProps) {
    const {
        f,
        account,
        blockedFields,
        editApplicationId,
        stateApplicationId,
        stateCreated,
        stateType,
        onApply,
        onChangePassword,
        onChange2FA,
        onChangeEmail,
        onChangePhone,
        onShowApplications,
        onShowMakeStatement,
        isApplicationButtonEnable
    } = props;
    const { t } = useTranslation();
    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    const auth = useSelector((state: ReduxState) => state.auth);
    const blockedByStatement = auth.user ? isBlockedByStatement(auth.user) : false;
    const withStateApplication = auth.user ? hasStateApplication(auth.user) : false;
    const withSession = auth.user ? hasGameSession(auth.user) : false;
    const Button = ({ text, ...props }: {[k: string]: any}) => {
        return (
            <button className="btn btn-primary btn-block" {...props} style={{whiteSpace: "initial"}}>{text}</button>
        );
    };

    Button.propTypes = {
        disabled: PropTypes.bool,
        text: PropTypes.string.isRequired,
        props: PropTypes.array,
    };

    function handleApply(e: any) {
        e.preventDefault();
        onApply();
    }

    function handleShowMakeBlockStatement(e: any) {
        e.preventDefault();
        onShowMakeStatement('BLOCK');
    }

    function handleShowMakeUnblockStatement(e: any) {
        e.preventDefault();
        onShowMakeStatement('UNBLOCK');
    }

    function handleShowMakeDeleteStatement(e: any) {
        e.preventDefault();
        onShowMakeStatement('DELETE');
    }

    function handleShowApplications(e: any) {
        e.preventDefault();
        onShowApplications();
    }

    function handleChangePassword(e: any) {
        e.preventDefault();
        onChangePassword();
    }

    const handleChange2FA = (enable: boolean) => (e: any) => {
        e.preventDefault();
        onChange2FA(enable);
    };

    function renderButtons() {
        return (
            <div>
                {/*<div className="row">*/}
                {/*    <div className="col-md-6">*/}
                        {
                            !account.foreignCitizen &&
                            <Button text={t(f.i18nPrefix + "application.apply")} onClick={handleApply} disabled={blockedByStatement || withSession}/>
                        }
                        <Button text={t(f.i18nPrefix + "changePassword")} onClick={handleChangePassword} disabled={blockedByStatement || withStateApplication}/>
                        <Button text={t(f.i18nPrefix + "2fa." + (account.use2FA ? "disable" : "enable"))}
                                onClick={handleChange2FA(!account.use2FA)}
                                disabled={blockedByStatement || withStateApplication}/>
                        {
                            isApplicationButtonEnable() &&
                            <Button text={t(f.i18nPrefix + "statements.all")} onClick={handleShowApplications} disabled={blockedByStatement}/>
                        }
                        <Button text={t(f.i18nPrefix + "statements.deletion")} onClick={handleShowMakeDeleteStatement} disabled={withStateApplication || withSession}/>
                        <Button text={t(f.i18nPrefix + "statements.blocking")} onClick={handleShowMakeBlockStatement} disabled={blockedByStatement || withStateApplication || withSession}/>
                        <Button text={t(f.i18nPrefix + "statements.unlock")} onClick={handleShowMakeUnblockStatement} disabled={withStateApplication}/>
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    }

    if (isMobile) {
        return <div>
            <MobileDualHeader firstHeader={t(f.i18nPrefix + "title")}/>
            <form name="personal-info" className="form-horizontal">
                {editApplicationId && <p className="text-center">{t(f.i18nPrefix + "application.hasEditApplication")}</p>}
                {stateApplicationId && <p className="text-center">{t(f.i18nPrefix + "application.hasStateApplication." + stateType, {id: stateApplicationId, date: stateCreated, email: account.email})}</p>}
                {
                    "BLOCKED_CONDITIONALLY" === account.participantStatus && (
                        <h2 className="text-center" style={{ color: 'red' }}>{t(f.i18nPrefix + "BLOCKED_CONDITIONALLY")}</h2>)
                }
                <div className="row">
                    <div className="col-md-6">
                        <NameBirthDateGenderForm f={f} readOnly blockedFields={blockedFields}/>
                    </div>

                </div>

                {
                    account.foreignCitizen && account.foreignCitizen.migrationRegistration
                    && <MigrationRegistrationForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }
                {
                    account.foreignCitizen && account.foreignCitizen.visa
                    && <VisaForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }
                {
                    account.foreignCitizen && account.foreignCitizen.localRegistration
                    && <AddressInRFForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }
                {
                    account.foreignCitizen && account.foreignCitizen.foreignRegistration
                    && <AddressInOwnCountryForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }
                <PassportForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                {
                    !account.foreignCitizen
                    && <AddressForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }

                <ContactInformationForm f={f} t={t}
                                        onChangeEmail={onChangeEmail}
                                        onChangePhone={onChangePhone}
                                        blockedFields={blockedFields}
                                        disabled={blockedByStatement}
                />

                <div style={{marginTop: 30}}>{renderButtons()}</div>

            </form>
        </div>;
    } else {
        return <div>
            <form name="personal-info" className="form-horizontal">
                <h2 className="text-center">{t(f.i18nPrefix + "title")}</h2>
                {editApplicationId && <p className="text-center">{t(f.i18nPrefix + "application.hasEditApplication")}</p>}
                {stateApplicationId && <p className="text-center">{t(f.i18nPrefix + "application.hasStateApplication." + stateType, {id: stateApplicationId, date: stateCreated, email: account.email})}</p>}
                {blockedByStatement && <p className="text-center">{t('personal.blockedByStatement')}</p>}
                {
                    "BLOCKED_CONDITIONALLY" === account.participantStatus && (
                        <h2 className="text-center" style={{ color: 'red' }}>{t(f.i18nPrefix + "BLOCKED_CONDITIONALLY")}</h2>)
                }

                <div className="row">
                    <div className="col-md-6">
                        <NameBirthDateGenderForm f={f} readOnly blockedFields={blockedFields}/>
                    </div>
                    <div className="col-md-6">
                        {renderButtons()}
                    </div>

                </div>
                {
                    account.foreignCitizen && account.foreignCitizen.migrationRegistration
                    && <MigrationRegistrationForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }
                {
                    account.foreignCitizen && account.foreignCitizen.visa
                    && <VisaForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }
                {
                    account.foreignCitizen && account.foreignCitizen.localRegistration
                    && <AddressInRFForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }
                {
                    account.foreignCitizen && account.foreignCitizen.foreignRegistration
                    && <AddressInOwnCountryForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }
                <PassportForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                <AdditionalInfoForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                {
                    !account.foreignCitizen
                    && <AddressForm f={f} t={t} readOnly blockedFields={blockedFields}/>
                }

                <ContactInformationForm f={f} t={t}
                                        onChangeEmail={onChangeEmail}
                                        onChangePhone={onChangePhone}
                                        blockedFields={blockedFields}
                                        disabled={blockedByStatement || withStateApplication}
                />
            </form>
        </div>;
    }
}

export default compose<React.ComponentType<Omit<PersonalInfoProps, 'f'>>>(
    withTranslation(),
    connect((state: ReduxState) => ({ minAge: state.settings.minAge })),
    form({
        i18nPrefix,
        initialModel: ({ account }: {account: any}) => account,
        schema: (account: any, { t, minAge }: {[k: string]: any}) => {
            const now = moment();
            const birthDate = account.birthDate
                ? moment(account.birthDate, "YYYY-MM-DD")
                : null;

            return {
                ...NameBirthDateGenderForm.schema(now, t, i18nPrefix, minAge),
                ...MigrationRegistrationForm.schema(t, i18nPrefix),
                ...VisaForm.schema,
                ...AddressInRFForm.schema,
                ...AddressInOwnCountryForm.schema,
                ...PassportForm.schema(now, birthDate),
                ...AdditionalInfoForm.schema,
                ...AddressForm.schema,
                ...ContactInformationForm.schema,
            };
        }
    })
)(PersonalInfo);
