import {withTranslation} from "react-i18next";
import React from "react";
import PrintButton from "../commons/comps/print-button/Print";
import CloseButton from "../commons/comps/close-button/Close";
import classNames from "classnames";
import './chessLive.css'
import {dateFormatter} from "../commons/DateTimeUtils";
import 'chessboard-element';
import './board.css';
import {getSquareColor} from './chessUtils';

class QuittanceHeader extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render () {
        const {print, close, t, receiptNo} = this.props;
        return (
            <div className={classNames("cl-header", "bg")}>
                <PrintButton print={ print }/>
                <h2>{t("quittance.chesslive.title", { receiptNumber : receiptNo })}</h2>
                <div className={"cl-text-bold"}>{t("quittance.chesslive.subtitle" )}</div>
                <CloseButton close={ close }/>
            </div>
        );
    }
}

class QuittanceTop extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render () {
        const {t, stepNo, ptpId, championship, tour, chessInfo, tournament} = this.props;
        const event = ptpId === 3 ? "quittance.chesslive.event.eb" : "quittance.chesslive.event.regular";

        return (
            <div className={"cl-top"}>
                <div className={"cl-text-subheader"}>{ t("quittance.chesslive.stepNo") + stepNo }</div>
                <div className={"cl-text-bold"}>{ t("quittance.chesslive.event.title") }</div>
                <div className={"cl-text"}>{ t(event) }</div>
                <div className={"cl-text"}>{ t("quittance.chesslive.event.championship") + ": " + championship }</div>
                <div className={"cl-text"}>{ t("quittance.chesslive.event.tour") + ": " + tour }</div>
                <div className={"cl-text-bold"}>{ t("quittance.chesslive.moveInfo.matchId", { matchId: chessInfo.matchId }) }</div>
                <div className={"cl-text-bold"}>{ t("quittance.chesslive.moveInfo.moveNumber", { moveNumber: chessInfo.moveNumber }) }</div>
                <div className={"cl-text"}>{ t("quittance.chesslive.moveInfo.tournament", { tournament: tournament }) }</div>
            </div>
        );
    }
}

class QuittanceMiddle extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    getCategoryCoefficient = (t, ptpId, selection, game) => {
        return ptpId === 3
            ? t('quittance.chesslive.selection.ebSteps.' + selection)
            : t("quittance.chesslive.selection." + game.toLowerCase() + "." + selection)
    }

    render () {
        const {t, currency, rate, ptpId, selection, game, coefficient} = this.props;

        return (
            <div className={"cl-middle bg"}>
                <div className={"cl-text-bold"}>{ t("quittance.chesslive.moveInfo.categoryCoefficient") + this.getCategoryCoefficient(t, ptpId, selection, game) }</div>
                <div className={"cl-text-bold"}>{ t("quittance.chesslive.rate." + currency, rate) }</div>
                { coefficient ? (<div className={"cl-text-bold"}>{ t("quittance.chesslive.moveInfo.coefficient") + coefficient }</div>) : ""}
            </div>
        );
    }
}

const ChessPiecesByGames = new Map([
    [254, ['wN', 'bN']],
    [255, ['wB', 'bB']],
    [256, ['wN', 'bN']],
    [257, ['wB', 'bB']],
    [270, ['wQ', 'bQ']],
    [271, ['wQ', 'bQ']],
    [272, ['wR', 'bR']],
    [273, ['wR', 'bR']],
    [274, ['wP', 'bP']],
    [275, ['wP', 'bP']],
    [285, ['wN', 'bN']],
    [286, ['wB', 'bB']],
    [287, ['wN', 'bN']],
    [288, ['wB', 'bB']],
    [289, ['wQ', 'bQ']],
    [290, ['wQ', 'bQ']],
    [291, ['wR', 'bR']],
    [292, ['wR', 'bR']],
    [293, ['wP', 'bP']],
    [294, ['wP', 'bP']],
    [295, ['wK', 'wQ', 'bK', 'bQ']],
    [296, ['wK', 'wQ', 'bK', 'bQ']],
    [297, ['wK', 'wN', 'bK', 'bN']],
    [298, ['wK', 'wN', 'bK', 'bN']],
    [299, ['wK', 'wB', 'bK', 'bB']],
    [300, ['wK', 'wB', 'bK', 'bB']],
    [301, ['wK', 'wQ', 'bK', 'bQ']],
    [302, ['wK', 'wQ', 'bK', 'bQ']],
    [303, ['wK', 'wN', 'bK', 'bN']],
    [304, ['wK', 'wN', 'bK', 'bN']],
    [305, ['wK', 'wB', 'bK', 'bB']],
    [306, ['wK', 'wB', 'bK', 'bB']],
    [307, ['wK', 'wQ', 'bK', 'bQ']],
    [308, ['wK', 'wQ', 'bK', 'bQ']],
    [309, ['wN', 'bN']],
    [310, ['wN', 'bN']],
    [311, ['wB', 'bB']],
    [312, ['wB', 'bB']],
    [313, ['wR', 'bR']],
    [314, ['wR', 'bR']],
    [315, ['wK', 'wQ', 'bK', 'bQ']],
    [316, ['wK', 'wQ', 'bK', 'bQ']],
    [317, ['wN', 'bN']],
    [318, ['wN', 'bN']],
    [319, ['wB', 'bB']],
    [320, ['wB', 'bB']],
    [321, ['wR', 'bR']],
    [322, ['wR', 'bR']],
    [323, ['wN', 'bN']],
    [324, ['wB', 'bB']],
    [325, ['wN', 'bN']],
    [326, ['wB', 'bB']],
    [327, ['wQ', 'bQ']],
    [328, ['wQ', 'bQ']],
    [329, ['wR', 'bR']],
    [330, ['wR', 'bR']],
    [331, ['wP', 'bP']],
    [332, ['wP', 'bP']],
    [333, ['wK', 'wQ', 'bK', 'bQ']],
    [334, ['wK', 'wQ', 'bK', 'bQ']],
    [335, ['wK', 'wN', 'bK', 'bN']],
    [336, ['wK', 'wN', 'bK', 'bN']],
    [337, ['wK', 'wB', 'bK', 'bB']],
    [338, ['wK', 'wB', 'bK', 'bB']],
    [339, ['wK', 'wQ', 'bK', 'bQ']],
    [340, ['wK', 'wQ', 'bK', 'bQ']],
    [341, ['wN', 'bN']],
    [342, ['wN', 'bN']],
    [343, ['wB', 'bB']],
    [344, ['wB', 'bB']],
]);

export const ChessLiveGames = new Map([
    [254, 'GAME1001'],
    [255, 'GAME1002'],
    [256, 'GAME1003'],
    [257, 'GAME1004'],
    [270, 'GAME1005'],
    [271, 'GAME1006'],
    [272, 'GAME1007'],
    [273, 'GAME1008'],
    [274, 'GAME1009'],
    [275, 'GAME1010'],
    [295, 'GAME1011'],
    [296, 'GAME1012'],
    [297, 'GAME1013'],
    [298, 'GAME1014'],
    [299, 'GAME1015'],
    [300, 'GAME1016'],
    [307, 'GAME1017'],
    [308, 'GAME1018'],
    [309, 'GAME1019'],
    [310, 'GAME1020'],
    [311, 'GAME1021'],
    [312, 'GAME1022'],
    [313, 'GAME1023'],
    [314, 'GAME1024'],
    [285, 'GAME1025'],
    [286, 'GAME1026'],
    [287, 'GAME1027'],
    [288, 'GAME1028'],
    [289, 'GAME1029'],
    [290, 'GAME1030'],
    [291, 'GAME1031'],
    [292, 'GAME1032'],
    [293, 'GAME1033'],
    [294, 'GAME1034'],
    [301, 'GAME1035'],
    [302, 'GAME1036'],
    [303, 'GAME1037'],
    [304, 'GAME1038'],
    [305, 'GAME1039'],
    [306, 'GAME1040'],
    [315, 'GAME1041'],
    [316, 'GAME1042'],
    [317, 'GAME1043'],
    [318, 'GAME1044'],
    [319, 'GAME1045'],
    [320, 'GAME1046'],
    [321, 'GAME1047'],
    [322, 'GAME1048'],
    [323, 'GAME1049'],
    [324, 'GAME1050'],
    [325, 'GAME1051'],
    [326, 'GAME1052'],
    [327, 'GAME1053'],
    [328, 'GAME1054'],
    [329, 'GAME1055'],
    [330, 'GAME1056'],
    [331, 'GAME1057'],
    [332, 'GAME1058'],
    [333, 'GAME1059'],
    [334, 'GAME1060'],
    [335, 'GAME1061'],
    [336, 'GAME1062'],
    [337, 'GAME1063'],
    [338, 'GAME1064'],
    [339, 'GAME1065'],
    [340, 'GAME1066'],
    [341, 'GAME1067'],
    [342, 'GAME1068'],
    [343, 'GAME1069'],
    [344, 'GAME1070']
]);

const boardStyles = document.createElement('style');
document.head.append(boardStyles);

class ChessBoard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.state.board) {
            const {chessInfo, ptpId, priId, withPrize} = this.props;
            const styles = [];
            styles.push(this.initBoardTheme(ptpId));
            // const move = chessInfo.moveInfo.substring(chessInfo.moveInfo.length - 5);
            // this.state.board.move(move);
            if (ptpId === 3) {
                const m = chessInfo.moveInfo.substring(chessInfo.moveInfo.length - 2);
                styles.push(this.highlightEBSquare(m));
            } else if (withPrize) {
                const squares = this.findChessPiecesOnBoard(this.state.board.position, ChessPiecesByGames.get(priId));
                squares.forEach(sq => {
                    styles.push(this.highlightSquare(sq));
                });
            }
            this.updateBoardStyles(styles);
        }
    }

    highlightEBSquare = (square) => {
        return `chess-board::part(${square}) {
            box-shadow: inset 0 0 3px 3px red;
        }`;
    };

    highlightSquare = (square) => {
        return `chess-board::part(${square}) {
            box-shadow: inset 0 0 5px 5px yellow;
        }`;
    };

    initBoardTheme = (ptpId) => {
        const black = ptpId === 2 ? '#3988a5' : '#8c6742';
        const white = ptpId === 2 ? '#c6d2d6' : '#e7c194';
        return this.changeSquareColors(white, black);
    };

    changeSquareColors = (white, black) => {
        return `chess-board::part(white) {
            --light-color: ${white};
        }
        chess-board::part(black) {
            --dark-color: ${black};
        }`;
    };

    updateBoardStyles = (styles) => {
        boardStyles.textContent = styles.join('\n');
    };

    findChessPiecesOnBoard = (board, pieces) => {
        return Object.keys(board).filter(square => pieces.includes(board[square]));
    };

    render () {
        const {chessInfo, ptpId} = this.props;
        const square = chessInfo.moveInfo.substring(chessInfo.moveInfo.length - 2);
        const ebClass = ptpId === 3
            ? getSquareColor(square) === 'light' ? "cl-white-square" : "cl-black-square"
            : "";
        const squareLetter = chessInfo.moveInfo.substring(chessInfo.moveInfo.length - 2, chessInfo.moveInfo.length - 1);
        const left = ['a', 'b', 'c', 'd'];

        return (
            <div className={"cl-board-container"}>
                <div className={"cl-square"}>
                    {
                        left.includes(squareLetter) && <div className={ebClass}/>
                    }
                </div>
                <div className={"cl-board"}>
                    <chess-board
                        position={chessInfo.fen}
                        hide-notation
                        ref={(e) => this.state.board = e}>
                    </chess-board>
                </div>
                <div className={"cl-square"}>
                    {
                        !left.includes(squareLetter) && <div className={ebClass}/>
                    }
                </div>
            </div>
        );
    }
}

class QuittanceBottom extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render () {

        const {t, registrationTime, calcTime, currency, chessInfo, prize, tz, ptpId, sebInfo} = this.props;
        return (
            <div className={'cl-bottom'}>
                <div className={"cl-text"}>{ t('quittance.chesslive.registrationTime', { time: dateFormatter(registrationTime, tz).slice(0, 14) + dateFormatter(registrationTime, tz).slice(14) }) }</div>
                <div className={"cl-text"}>{ t('quittance.chesslive.moveInfo.moveDate', { time: dateFormatter(chessInfo.moveDate, tz).slice(0, 14) + dateFormatter(chessInfo.moveDate, tz).slice(14)}) }</div>
                <div className={"cl-text"}>{ t('quittance.chesslive.calculationTime', { time : dateFormatter(calcTime, tz).slice(0, 14) + dateFormatter(calcTime, tz).slice(14) }) }</div>
                {
                    ptpId === 2 && <div className={"cl-text"}>
                        { t("quittance.chesslive.sebCounter", { id: sebInfo.stepId, count: sebInfo.stepCount}) }
                    </div>
                }
                {
                    Number(prize.currency) > 0
                        ? <div className={"cl-text"}>{ t("quittance.chesslive.prize." + currency, prize) }</div>
                        : ptpId === 2
                            ? <div className={"cl-text"}>{ t("quittance.chesslive.prize.sebNoResult") }</div>
                            : <div className={"cl-text"}>{ t("quittance.chesslive.prize.noResult") }</div>
                }
                {
                    ptpId === 2 && sebInfo.stepId === sebInfo.stepCount
                        ? <div className={"cl-bold-bottom-text "}>{ t("quittance.chesslive.sebLastStep") }</div>
                        : <div className={"cl-bold-bottom-text "}>{ t("quittance.chesslive.ptp." + ptpId) }</div>
                }
                {
                    sebInfo && sebInfo.initStep && <div className={"cl-text-red"}>{ t("quittance.chesslive.sebAvailable") }</div>
                }
            </div>
        );
    }
}

class Quittance extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render () {
        const {step, t, print, close} = this.props;
        let event = step.events[0];
        const receiptNo = step.receiptNumber;
        const game = ChessLiveGames.get(step.priId);
        const stepNo = step.id;
        const board = event.clContent.board;
        const tournament = event.clContent.tournamentName;
        const tournamentStart = event.clContent.tournamentStartTime;
        const championship = event.chessTourInfo.championshipName;
        const tour = event.chessTourInfo.name;
        const sebInfo = event.sebInfo;
        const tz = -new Date().getTimezoneOffset();
        const registrationTime = step.date;
        const calcTime = step.updated;
        const currency = step.currencyShowType;
        const denomination  = step.denomination;

        const rate = {
            credits: Math.round(step.betAmount / denomination),
            currency: step.betAmount
        };
        const win = step.ptpId === 2 && step.winAmount > 0 ? step.winAmount - step.betAmount : step.winAmount;
        const prize = {
            credits: Math.round(win / denomination),
            currency: win
        };
        const selection = step.events[0].selection;
        const coefficient = step.coefficient && step.coefficient !== '0' ? step.coefficient : null;

        return (
            <div className={classNames("cl-quittance", game)}>
                <QuittanceHeader print={print} close={close} t={t} receiptNo={receiptNo}/>
                <QuittanceTop t={t} stepNo={stepNo} ptpId={step.ptpId} championship={championship} tour={tour} chessInfo={board} tournament={tournament}/>
                <ChessBoard chessInfo={board} ptpId={step.ptpId} priId={step.priId} withPrize={step.winAmount > 0}/>
                <QuittanceMiddle t={t} currency={currency} rate={rate} game={game} selection={selection} ptpId={step.ptpId} coefficient={coefficient}/>
                <QuittanceBottom t={t} registrationTime={registrationTime} currency={currency} chessInfo={board} prize={prize}
                                 tz={tz} calcTime={calcTime} ptpId={step.ptpId} sebInfo={sebInfo}/>
            </div>
        );
    }
}

export default withTranslation()(Quittance);
