import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Route, BrowserRouter as Router, RouteComponentProps} from "react-router-dom";
import {compose} from "redux";
import {goToHomePage, queryAndUpdateAuth, resetAuth} from "./Auth";

import i18next from "i18next";
import axios from "axios";
import {MobileTpl} from "./master/mobile/MobileTpl";
import Tpl from "./master/Tpl";
import {ReduxState} from "./redux/redux";
import {setSettings, Settings} from "./redux/settings";
import {useAxiosInterceptor, useTicker} from "./util/hooks";
import {NotificationSystem, useNotifications} from "./util/Notifications";
import {preloadGet} from "./util/preload";
import {langUrlRegex, up} from "./util/urlPrefix";
import {Authentication} from "./redux/auth";

export interface RootRouteProps {
    lang: string;
}

interface ReduxDispatchProps {
    setSettings: (settings: Settings) => void;
}

interface RootProps extends RouteComponentProps<RootRouteProps>, ReduxDispatchProps {
    notificationSystem: any;
    settings: Settings;
}

interface ErrorDescriptor {
    code: string;
    args?: any;
}

function showErrors(system: NotificationSystem, errors: Array<ErrorDescriptor> | ErrorDescriptor) {
    if (!Array.isArray(errors)) {
        errors = [errors];
    }
    system.addNotification({
        title: i18next.t("error.title"),
        message: errors.map(e => i18next.t(e.code, e.args)).join("\n")
    });
}

const Root: React.FC<RootProps> = ({match, history, settings, setSettings}) => {
    const dispatch = useDispatch();
    const notificationSystem = useNotifications();
    useEffect(() => {
        setSettings(settings);
    }, []);
    useEffect(() => {
        // Dont change language if there is no lang pass at URL params
        if (match.params.lang) {
            i18next.changeLanguage(match.params.lang);
        }
        document.title = i18next.t("title");
    }, [match.params.lang]);

    useEffect(() => {
        const query = document.referrer.split("?")[1];
        query && query.split("&").forEach(r => {
            const kv = r.split("=");
            if (kv[0] === 'lang') {
                const lang = kv[1] === "ru" ? "ru" : "en";
                i18next.changeLanguage(lang)
            }
        })
    }, []);

    React.useEffect(() => {
        if (new URLSearchParams(document.location.search).get("viewType")) {
            dispatch({type: "TOGGLE_MOBILE_VIEW_STATUS"});
        }
    }, [document.location.search]);

    let currentAuth: Authentication | null = null;
    useTicker(() => {
        queryAndUpdateAuth().then(auth => {
            if (currentAuth && currentAuth.authenticated && !auth.authenticated) {
                goToHomePage(settings.mainUrl);
            }
            currentAuth = auth;
        })
    }, 5000);
    useAxiosInterceptor(axios.interceptors.response, r => {
        if (r.data && r.data.apiErrors) {
            const errors = r.data.apiErrors;
            showErrors(notificationSystem, errors);
            return Promise.reject(errors);
        }
        return r;
    }, err => {
        const resp = err.response;
        if (resp.status === 401) {
            resetAuth();
            history.push(`${up(match)}/login`);
        } else if (resp.data && resp.data.apiErrors) {
            showErrors(notificationSystem, resp.data.apiErrors);
        } else if (resp.status === 403) {
            showErrors(notificationSystem, {code: "error.accessDenied"});
        } else if (resp.data && resp.data.code === "fias.service.unavailable.with.input") {
            return;
        } else if (resp.data.code === "pds.unavailable") {
            showErrors(notificationSystem, {code: "error.pdsUnavailable"})
        } else {
            showErrors(notificationSystem, {code: "error.unknown"})
        }
        return Promise.reject(err);
    });

    const isMobile = useSelector((state: ReduxState) => state.isMobileView);
    return isMobile ? <MobileTpl/> : <Tpl/>;
};

const RootHOC = compose(
    connect<{}, ReduxDispatchProps, {}, ReduxState>(null, dispatch => ({
        setSettings: (settings: Settings) => dispatch(setSettings(settings))
    })),
    preloadGet<{ settings: Settings }, Settings, RootProps>("/api/settings", "settings")
)(Root);

const App = () => <Router>
    <Route path={`/:lang(${langUrlRegex})?`} strict component={RootHOC}/>
</Router>;

export default App;
