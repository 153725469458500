import axios from "axios";
import React from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

import LabelInput from "../forms/LabelInput";
import { UPLink } from "../util/urlPrefix";

import { form, resultType } from "../validation/FormController";
import { maxLength, minLength, required, ruPhone } from "../validation/functionalPlugins";
import {AuthRequestType, AuthResponseType, AuthStatusEnum, StatementType} from "./AuthTypes";
import {connect, useSelector} from "react-redux";
import {ReduxState} from "../redux/redux";
import {CaptchaWidget} from "../captcha/Captcha";
import moment from "moment";

type LoginFormPropsInitial = {
    onLogin: any;
    isFormRegistrationVisible: boolean;
}

type LoginFormProps = {
    t: any;
    f: resultType;
    showAnotherLkUrl: boolean;
    anotherLkUrl: string;
    [k: string]: any;
} & LoginFormPropsInitial;

type LoginFormState = {
    status: AuthStatusEnum;
    club: string;
    [k: string]: any;
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {

    constructor(props: LoginFormProps) {
        super(props);
        this.state = {
            status: AuthStatusEnum.OK,
            statementType: undefined,
            club: "",
            isLoading: false,
            credentials: null,
            captchaSolved: false
        };
    }

    onAuth(resp: AuthResponseType & {phone: string}) {
        if (!resp.ok) {
            this.setState({
                status: resp.status,
                statementType: resp.statementType,
                club: resp.statementSubmittedClub,
                applicationNumber: resp.applicationNumber,
                applicationDate: resp.applicationDate
            });
            return;
        }
        this.props.onLogin(resp);
    }

    complete() {
        this.setState(prev => ({...prev, isLoading: false}))
    }

    handleSubmit(credentials: AuthRequestType) {
        const { captchaSolved } = this.state;
        this.setState({
            credentials: credentials
        });
        if (captchaSolved) {
            this.handleAuth();
            return;
        }
        axios.get("/api/auth/check-counter/" + credentials.phone)
            .then(resp => {
                const isNeedCAPTCHA = resp.data.value;
                if (isNeedCAPTCHA) {
                    this.setState({
                        isNeedCAPTCHA: isNeedCAPTCHA,
                        captchaSolved: false
                    });
                } else {
                    this.setState({
                        isNeedCAPTCHA: isNeedCAPTCHA,
                        captchaSolved: true
                    });
                    this.handleAuth();
                }
            }, err => console.log(err));
    }

    handleAuth() {
        this.setState({
            status: AuthStatusEnum.OK,
            club: "",
            isLoading: true,
            statementType: undefined,
            isNeedCAPTCHA: false,
            captchaSolved: false
        });
        const { credentials } = this.state;
        axios.post<AuthResponseType>("/api/auth/login", credentials)
            .then(resp => this.onAuth({ ...resp.data, phone: credentials.phone }),
                err => console.log(err))
            .then(() => this.complete(), () => this.complete())
    }

    setCaptchaResolved(ok: boolean) {
        if (ok) {
            this.setState({
                captchaSolved: ok
                // isNeedCAPTCHA: !ok
            });
            // this.handleAuth();
        }
    }

    render() {
        const { isNeedCAPTCHA, captchaSolved } = this.state;
        // if (isNeedCAPTCHA) {
        //     return (
        //         <div className="col-md-8 col-md-offset-2">
        //             <CaptchaWidget form="login" verified={ok => this.setCaptchaResolved(ok)} />
        //         </div>
        //     );
        // } else {
            const { t, f, showAnotherLkUrl, anotherLkUrl, isFormRegistrationVisible } = this.props;
            console.log("url =" + anotherLkUrl);
            // const anotherLkUrl = useSelector((state: ReduxState) => state.settings.anotherLkUrl);
            const { isLoading, status, club, statementType, applicationNumber, applicationDate } = this.state;
            if (!f.submit) {
                return null;
            }
            let statementTypeText;
            if (!!statementType) {
                statementTypeText = t("login.statementType." + statementType);
            }
            return (
                <form name="login" className="form-horizontal" onSubmit={f.submit((credentials: AuthRequestType) => this.handleSubmit(credentials))}>

                    <div className="row">
                        <div className="col-md-6 col-md-offset-2">
                            <LabelInput f={f("phone")} placeholder="+7 (___)___-__-__" extraValid={status !== 'NOT_AUTHENTICATED'} />
                            <LabelInput f={f("password")} type="password" extraValid={status !== 'NOT_AUTHENTICATED'} />
                        </div>
                    </div>
                    {status !== "OK" && !isNeedCAPTCHA &&
                        <div className="row">
                            <div className="col-md-4 col-md-offset-4">
                                <div className="error">{t("login." + status, {
                                    club: club,
                                    statementType: statementTypeText,
                                    applicationNumber,
                                    applicationDate: applicationDate ? moment(applicationDate).format("DD.MM.YYYY") : undefined
                                })}</div>
                            </div>
                        </div>}
                    {
                        isNeedCAPTCHA && <div className="col-md-4 col-md-offset-4">
                            <CaptchaWidget form="login" verified={ok => this.setCaptchaResolved(ok)}/>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4">
                            <button className="btn btn-primary form-control" disabled={!(f.errors.length === 0) || isLoading || (!captchaSolved && isNeedCAPTCHA) } type="submit">{t("login.submit.label")}</button>
                        </div>
                    </div>
                    {
                        showAnotherLkUrl && <>
                            <br />
                            <div className="row">
                                <div className="col-md-6 col-md-offset-3 text-center">
                                    <a href={anotherLkUrl}>{t("login.anotherLk")}</a>
                                </div>
                            </div>
                        </>
                    }

                    <br />
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            {isFormRegistrationVisible && <p className="text-center">{t("login.notRegisteredYet")} <UPLink to="/register">{t("register.title")}</UPLink></p>}
                            <p className="text-center">{t("login.forgotPassword")} <UPLink to="/restore">{t("restore.title")}</UPLink></p>
                        </div>
                    </div>
                </form>
            );
        // }
    }
}

export default compose<React.ComponentType<LoginFormPropsInitial>>(
    withTranslation(),
    connect((state: ReduxState) =>
        ({ showAnotherLkUrl: state.settings.showAnotherLkUrl, anotherLkUrl: state.settings.anotherLkUrl })),
    form({
        i18nPrefix: "login.",
        schema: {
            phone: [
                ruPhone(),
                required(),
            ],
            password: [
                required(),
                minLength(6),
                maxLength(20)
            ]
        }
    })
)(LoginForm);
