import React from 'react';
import axios from "axios";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import ReactModal from "react-modal";

export const EditApplicationExistDialog = (props) => {
    const {onRequestHide, isOpen, onContinue} = props;
    const isMobile = useSelector((state) => state.isMobileView);
    const {t} = useTranslation();
    const i18nPrefix = "personal.statements.";
    const tr = (key) => t(i18nPrefix + key);

    const Content = () => {
        return (
            <div className="form-group row modal-window" style={{minWidth: '1000px'}}>
                <div dangerouslySetInnerHTML={{__html: tr("exists.EDIT")}}/>
                <div className="button-container">
                    <button className="btn btn-primary" onClick={onContinue}>{tr("continue")}</button>
                    <button className="btn btn-default" onClick={onRequestHide}>{tr("cancel")}</button>
                </div>
            </div>
        )
    }

    if (isMobile) {
        return <div>
            <MobileDualHeader firstHeader={t("personal.title")} secondHeader={tr('title')}/>
            <Content/>
        </div>;
    } else {
        return <div>
            <ReactModal isOpen={isOpen}
                        className="Modal"
                        overlayClassName="Overlay"
                        onRequestClose={onRequestHide}>
                <Content/>
            </ReactModal>
        </div>
    }
}