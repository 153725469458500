import deepEqual from "deep-equal";
import {Reducer} from "redux";
import IdentificationType from "./IdentificationType";

export enum UserStatus {
    BLOCKED = "BLOCKED",
    FROZEN = "FROZEN",
    ACTIVE = "ACTIVE",
    OK = "OK",
    INN_OBTAINING = "INN_OBTAINING",
    INN_CONFIRMING = "INN_CONFIRMING"
}

export enum CupisBindClientStatus {
    NONE = "NONE",
    OK = "OK",
    CHANGED_PHONE_BY_CUPIS = "CHANGED_PHONE_BY_CUPIS",
    CHANGED_PHONE_BY_CLIENT = "CHANGED_PHONE_BY_CLIENT",
    BLOCKED_BY_CUPIS = "BLOCKED_BY_CUPIS",
    DELETED_BY_CUPIS = "DELETED_BY_CUPIS",
    BLOCKED_BY_CUPIS_AND_CHANGED_PHONE_BY_CUPIS = "BLOCKED_BY_CUPIS_AND_CHANGED_PHONE_BY_CUPIS",
    CHANGED_PD_BY_CUPIS = "CHANGED_PD_BY_CUPIS",
    REBIND_ERROR_FOR_DELETED_CLIENT = "REBIND_ERROR_FOR_DELETED_CLIENT",
    REBIND_ERROR_FOR_FIRST_REGISTRATION = "REBIND_ERROR_FOR_FIRST_REGISTRATION",
    REBIND_TIMEOUT_ERROR_FOR_DELETED_CLIENT = "REBIND_TIMEOUT_ERROR_FOR_DELETED_CLIENT",
    NOTIFICATION_TIMEOUT_ERROR_FOR_DELETED_CLIENT = "NOTIFICATION_TIMEOUT_ERROR_FOR_DELETED_CLIENT",
    REBIND_TIMEOUT_ERROR_FOR_FIRST_REGISTRATION = "REBIND_TIMEOUT_ERROR_FOR_FIRST_REGISTRATION",
    NOTIFICATION_TIMEOUT_ERROR_FOR_FIRST_REGISTRATION = "NOTIFICATION_TIMEOUT_ERROR_FOR_FIRST_REGISTRATION",
    SUCCESS_REBIND_FOR_DELETED_CLIENT = "SUCCESS_REBIND_FOR_DELETED_CLIENT",
    SUCCESS_REBIND_FOR_FIRST_REGISTRATION = "SUCCESS_REBIND_FOR_FIRST_REGISTRATION",
    ERROR_REBIND_FOR_DELETED_CLIENT = "ERROR_REBIND_FOR_DELETED_CLIENT",
    ERROR_REBIND_FOR_FIRST_REGISTRATION = "ERROR_REBIND_FOR_FIRST_REGISTRATION",
}

export enum SessionType {
    NONE = "NONE",
    SPORT_RB = "SPORT_RB",
    SPORT_MT = "SPORT_MT",
    SPORT_MB = "SPORT_MB",
    SPORT_SB = "SPORT_SB",
    ACTIVE_GAMES = "ACTIVE_GAMES",
    INACTIVE_GAMES = "INACTIVE_GAMES",
    CHESS_GAMES = "CHESS_GAMES"
}

export interface EventTypeAndGame {
    eventType: string;
    game: string;
    gameIndex: number;
}

export enum PcIdentificationStatus {
    NOT_IDENTIFIED = "NOT_IDENTIFIED",
    IDENTIFIED = "IDENTIFIED",
    REJECTED = "REJECTED"
}

export interface PcIdentification {
    type: IdentificationType;
    status: PcIdentificationStatus;
    rejectMessage?: string;
    address?: string;
    skype?: string;
}

export enum CupisIdentificationStatus {
    NONE = "NONE",
    INIT = "INIT",
    REGISTERED = "REGISTERED",
    PHONE_CONFIRMED = "PHONE_CONFIRMED",
    IDENTIFIED = "IDENTIFIED",
    IDENTIFY_CONFIRMED = "IDENTIFY_CONFIRMED",
    DONE = "DONE",
    DELETED = "DELETED",
    DUPLICATED = "DUPLICATED",
    PHONE_UPDATED = "PHONE_UPDATED",
    CLARIFICATION_PD = "CLARIFICATION_PD",
    CONFIRMED_PD = "CONFIRMED_PD"
}

export const isRegisterStageStatus = (status: CupisIdentificationStatus) => {
    return [
        CupisIdentificationStatus.NONE,
        CupisIdentificationStatus.INIT,
        CupisIdentificationStatus.REGISTERED,
        CupisIdentificationStatus.DELETED,
        CupisIdentificationStatus.DUPLICATED,
        CupisIdentificationStatus.PHONE_UPDATED,
        CupisIdentificationStatus.CONFIRMED_PD
    ].includes(status);
};

export const isInnNeedConfirm = (status: UserStatus) => {
    return status === UserStatus.INN_CONFIRMING;
};

export const isClarificationPDStatus = (status: CupisIdentificationStatus) => {
    return status === CupisIdentificationStatus.CLARIFICATION_PD;
};

export enum CupisIdentificationMethod {
    INTERVIEW = "INTERVIEW",
    BETSHOP = "BETSHOP",
    AGENT = "AGENT"
}

export interface CupisIdentification {
    ok: boolean;
    status: CupisIdentificationStatus;
    codeNum: number;
    errorCode: string;
    errorDescription: string;
    errorSource: string;
    clientStatus: CupisBindClientStatus;
}

export interface UserInfo {
    firstName: string;
    lastName: string;
    middleName?: string;
    balance: number;
    pcIdentification: PcIdentification;
    cupisIdentification: CupisIdentification;
    jackBetAvailable: boolean;
    closingAccountExist: boolean;
    sessionType: SessionType;
    status: UserStatus;
    frozenUpTo?: string;
    activeGames?: Array<EventTypeAndGame>;
    shiftActive: boolean;
    applicationExist: boolean;
    blockedByStatement: boolean;
    applications: {
        [key: string]: number
    };
}

export function hasStatement(user: UserInfo) {
    return [ApplicationType.BLOCK, ApplicationType.DELETE, ApplicationType.UNBLOCK, ApplicationType.EDIT]
        .some(type => user.applications[type]);
}

export function hasStateApplication(user: UserInfo) {
    return [ApplicationType.BLOCK, ApplicationType.DELETE, ApplicationType.UNBLOCK]
        .some(type => user.applications[type]);
}

export function hasGameSession(user: UserInfo) {
    return user.sessionType !== 'NONE';
}

export function hasEditStatement(user: UserInfo) {
    return !!user.applications[ApplicationType.EDIT];
}

export function isBlockedByStatement(user: UserInfo) {
    return user.blockedByStatement;
}

export enum ApplicationType  {
    VIEW = 'VIEW',
    CREATE = 'CREATE',
    CREATE_WEB = 'CREATE_WEB',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    BLOCK = 'BLOCK',
    UNBLOCK = 'UNBLOCK'
}

export interface Authentication {
    authenticated?: boolean;
    user: UserInfo | null;
}

export interface SetAuthAction {
    type: "SET_AUTH";
    auth: Authentication;
}

export function setAuth(a: Authentication): SetAuthAction {
    return {
        type: "SET_AUTH",
        auth: a
    };
}

export const AUTH_NOT_LOADED: Authentication = { user: null };
export const NOT_AUTHENTICATED: Authentication = {authenticated: false, user: null};
export const authReducer: Reducer<Authentication, SetAuthAction> = (state, action) => {
    return action.type === "SET_AUTH" && !deepEqual(action.auth, state) ? action.auth : (state || AUTH_NOT_LOADED);
};
