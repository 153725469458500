import math from "mathjs";

export const getCreditStepResult = ({kvitokData}) => kvitokData.CREDITS_RESULT;

export const prettyCoefficient = (coefficient) => {
    if (typeof coefficient !== "string") {
        return coefficient;
    }
    const delimiter = '/';
    if (coefficient.indexOf(delimiter) === -1) {
        return coefficient;
    }
    let parts = coefficient.split(delimiter);
    if(parts[0] === 'NaN') {
        return coefficient;
    }
    let f = math.fraction(parts[0], parts[1]);
    return f.toFraction(true);
};
