import React from 'react';
import axios from "axios";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {MobileDualHeader} from "../mobile-components/MobileDualHeader";
import ReactModal from "react-modal";
import {queryAndUpdateAuth} from "../Auth";

export const MakeStatementDialog = (props) => {
    const {onRequestHide, isOpen, type, loadApplicationsInfo,applicationSentNotification} = props;
    const [acceptText, setAcceptText] = React.useState("");
    const isMobile = useSelector((state) => state.isMobileView);
    const {t} = useTranslation();
    const i18nPrefix = "personal.statements.";
    const tr = (key) => t(i18nPrefix + key);

    React.useEffect(() => {
        if (isOpen) {
            setAcceptText(null)
            axios.get('/api/personal/get-accept-text', {params: props}).then(res => setAcceptText(res.data));
        }
    }, [isOpen, type])

    function handleSave() {
        axios.post('/api/personal/save-application', {type, text: acceptText, email: props.email})
            .then(resp=>{
                applicationSentNotification(resp.data);
            })
            .then(loadApplicationsInfo)
            .then(queryAndUpdateAuth)
            .then(onRequestHide)

    }

    const Content = () => {
        return (
            <div className="form-group row modal-window" style={{minWidth: '1000px'}}>
                <div className="between">
                    <button className="btn btn-primary" onClick={handleSave}>{tr("accept")}</button>
                    {!isMobile && <button className="btn btn-primary" onClick={onRequestHide}>{tr("cancel")}</button>}
                </div>
                <br/>
                <div style={{whiteSpace: 'pre-line'}}>{acceptText}</div>
            </div>
        )
    }

    if (isMobile) {
        return <div>
            <MobileDualHeader firstHeader={t("personal.title")} secondHeader={tr('title')}/>
            <Content/>
        </div>;
    } else {
        return <div>
            <ReactModal isOpen={isOpen}
                        className="Modal"
                        overlayClassName="Overlay"
                        onRequestClose={onRequestHide}>
                <Content/>
            </ReactModal>
        </div>
    }
}